import axiosInstance from "auth/FetchInterceptor";
import { CITY_TOUR_API, SEARCH_CITY_TOUR, DROPDOWN_PLACE } from "constants/ApiConstant";
const CityTourService = {};


CityTourService.getListCityTour = function (data) {
    return axiosInstance.post(SEARCH_CITY_TOUR, data);
};
CityTourService.getDropdownPlace = function () {
    return axiosInstance.get(DROPDOWN_PLACE, null);
};
CityTourService.getCityTourById = function (id) {
    return axiosInstance.get(`${CITY_TOUR_API}/${id}`, null);
};
CityTourService.createCityTour = function (data) {
    return axiosInstance.post(CITY_TOUR_API, data);
};
CityTourService.updateCityTour = function (data) {
    return axiosInstance.put(CITY_TOUR_API, data);
};
CityTourService.delCityTour = function (id) {
    return axiosInstance.delete(`${CITY_TOUR_API}/${id}`, null);
};

export default CityTourService;