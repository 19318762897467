import axiosInstance from "auth/FetchInterceptor";
import {
    SAVE_SYSTEM_SETTING_API,
    SAVE_NOTIFY_TIME_API,
    GET_SYSTEM_SETTING_OTHER_API,
    GET_CANCEL_ORDER_REASON,
    GET_REJECT_ORDER_REASON,
    GET_OFFLINE_REASON,
    REASON_CREATE,
    REASON_UPDATE,
    REASON_DELETE,
    GET_ALL_SHIFT_API,
    UPDATE_SHIFT_API,
} from "constants/ApiConstant";

const OtherSettingService = {};

OtherSettingService.saveSystemSettingApi = function (data) {
    return axiosInstance.post(SAVE_SYSTEM_SETTING_API, data);
};

OtherSettingService.saveNotifyTimeApi = function (data) {
    return axiosInstance.post(SAVE_NOTIFY_TIME_API, data);
};

OtherSettingService.getSystemSettingOthers = function () {
    return axiosInstance.get(GET_SYSTEM_SETTING_OTHER_API, null);
};

OtherSettingService.getCancelReason = function () {
    return axiosInstance.get(GET_CANCEL_ORDER_REASON, null);
};

OtherSettingService.getRejectReason = function () {
    return axiosInstance.get(GET_REJECT_ORDER_REASON, null);
};

OtherSettingService.getOfflineReason = function () {
    return axiosInstance.get(GET_OFFLINE_REASON, null);
};

OtherSettingService.createReason = function (data) {
    return axiosInstance.post(REASON_CREATE, data);
};

OtherSettingService.updateReason = function (data) {
    return axiosInstance.put(REASON_UPDATE, data);
};

OtherSettingService.delReason = function (id) {
    return axiosInstance.delete(`${REASON_DELETE}/${id}`, null);
};

OtherSettingService.getShift = function () {
    return axiosInstance.get(GET_ALL_SHIFT_API, null);
};

OtherSettingService.updateShift = function (data) {
    return axiosInstance.put(UPDATE_SHIFT_API, data);
};
export default OtherSettingService;