import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import settingSystem from './slices/settingSystemSlice';
import location from "./slices/locationSlice";
import orderApi from "./slices/OrderCustomerSlice";
import report from "./slices/reportSlice";
import notification from "./slices/NotificationSlice";
//aloha
import voucher from "./slices/VoucherSlice";
import customer from "./slices/CustomerSlice";
import employee from "./slices/EmployeeSlice";
import vehicle from "./slices/VehicleSlice";
import priceSetting from "./slices/PriceSettingSlice";
import driver from "./slices/DriverSlice";
import shiftDriver from "./slices/ShiftDriverSlice";
import warning from "./slices/WarningSlice";
import monitor from './slices/monitorSlide';
import place from "./slices/PlaceSlice";
import cityTour from "./slices/CityTourSlice";
import order from "./slices/OrderSlice";
import otherSetting from "./slices/OtherSettingSlice";
import driverFeedback from "./slices/DriverFeedbackSlice";
import workingArea from './slices/workingAreaSlice';
import news from './slices/NewsSlide';
import orderReason from './slices/OrderReasonSlice';
import otherService from './slices/OtherServiceSlice';
import language from './slices/LanguageSlice';
import collaborator from './slices/CollaboratorSlice';
import collaboratorPayment from './slices/CollaboratorPaymentSlice';
import collaboratorSetting from './slices/CollaboratorSettingSlice';
import settingHideOrder from './slices/SettingHideOrderSlide';


const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    settingSystem,
    location,
    customer,
    employee,
    orderApi,
    report,
    notification,
    driver,
    vehicle,
    priceSetting,
    voucher,
    shiftDriver,
    warning,
    monitor,
    place,
    cityTour,
    order,
    driverFeedback,
    otherSetting,
    workingArea,
    news,
    orderReason,
    otherService,
    language,
    collaborator,
    collaboratorPayment,
    collaboratorSetting,
    settingHideOrder,

    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
