import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    title: "Đăng nhập",
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "forgot-password",
    title: "Quên mật khẩu",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "reset-password",
    path: "/reset-password",
    title: "Đặt lại mật khẩu",
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "fullpage-news",
    path: "/full-page/news",
    title: "",
    component: React.lazy(() =>
      import("views/full-page/news")
    ),
  },
  {
    key: "fullpage-place",
    path: "/full-page/place",
    title: "",
    component: React.lazy(() =>
      import("views/full-page/place")
    ),
  },
  {
    key: "fullpage-voucher",
    path: "/full-page/voucher",
    title: "",
    component: React.lazy(() =>
      import("views/full-page/voucher")
    ),
  },
  {
    key: "fullpage-regulation",
    path: "/full-page/regulation",
    title: "",
    component: React.lazy(() =>
      import("views/full-page/vehicleRegulation")
    ),
  },
  {
    key: "fullpage-security",
    path: "/full-page/security",
    title: "",
    component: React.lazy(() =>
      import("views/full-page/security")
    ),
  },
  {
    key: "fullpage-other-service",
    path: "/full-page/other-service",
    title: "",
    component: React.lazy(() =>
      import("views/full-page/otherService")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    title: "Default",
    path: `${APP_PREFIX_PATH}/aloha-monitor/dashboard`,
    component: React.lazy(() => import("views/app-views/aloha-monitor/dashboard")),
  },
  {
    key: "monitor.dashboard",
    title: "Dashboard",
    path: `${APP_PREFIX_PATH}/aloha-monitor/dashboard`,
    component: React.lazy(() => import("views/app-views/aloha-monitor/dashboard")),
  },
  {
    key: "monitor.map",
    title: "Bản đồ",
    path: `${APP_PREFIX_PATH}/aloha-monitor/map`,
    component: React.lazy(() => import("views/app-views/aloha-monitor/map")),
  },
  {
    key: "monitor.trips",
    title: "trips",
    path: `${APP_PREFIX_PATH}/aloha-monitor/trips`,
    component: React.lazy(() => import("views/app-views/aloha-monitor/trips")),
  },
  {
    key: "monitor.warning",
    title: "warning",
    path: `${APP_PREFIX_PATH}/aloha-monitor/warning`,
    component: React.lazy(() => import("views/app-views/aloha-monitor/warning")),
  },
  {
    key: "monitor.shifts",
    title: "shifts",
    path: `${APP_PREFIX_PATH}/aloha-monitor/shifts`,
    component: React.lazy(() => import("views/app-views/aloha-monitor/shifts")),
  },
  {
    key: "monitor.report",
    title: "report",
    path: `${APP_PREFIX_PATH}/aloha-monitor/report`,
    component: React.lazy(() => import("views/app-views/aloha-monitor/report")),
  },
  {
    key: "information.voucher",
    title: "voucher",
    path: `${APP_PREFIX_PATH}/aloha-information/voucher`,
    component: React.lazy(() => import("views/app-views/aloha-information/voucher")),
  },

  {
    key: "information.voucher.uc",
    path: `${APP_PREFIX_PATH}/aloha-information/vouchers/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-information/voucher/Feature/CreateAndUpdate")
    ),
  },
  {
    key: "information.voucher.uc",
    path: `${APP_PREFIX_PATH}/aloha-information/vouchers/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-information/voucher/Feature/CreateAndUpdate")
    ),
  },
  {
    key: "information.news",
    title: "news",
    path: `${APP_PREFIX_PATH}/aloha-information/news`,
    component: React.lazy(() => import("views/app-views/aloha-information/news")),
  },
  {
    key: "information.news.uc",
    path: `${APP_PREFIX_PATH}/aloha-information/news/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-information/news/feature/CreateAndUpdate")
    ),
  },
  {
    key: "information.news.uc",
    path: `${APP_PREFIX_PATH}/aloha-information/news/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-information/news/feature/CreateAndUpdate")
    ),
  },
  {
    key: "information.otherService",
    title: "otherService",
    path: `${APP_PREFIX_PATH}/aloha-information/otherService`,
    component: React.lazy(() => import("views/app-views/aloha-information/otherService")),
  },
  {
    key: "information.otherService.uc",
    path: `${APP_PREFIX_PATH}/aloha-information/otherService/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-information/otherService/feature/CreateAndUpdate")
    ),
  },
  {
    key: "information.otherService.uc",
    path: `${APP_PREFIX_PATH}/aloha-information/otherService/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-information/otherService/feature/CreateAndUpdate")
    ),
  },
  {
    key: "settings.driver",
    title: "driver",
    path: `${APP_PREFIX_PATH}/aloha-settings/driver`,
    component: React.lazy(() => import("views/app-views/aloha-settings/driver")),
  },
  {
    key: "settings.driver.uc",
    path: `${APP_PREFIX_PATH}/aloha-settings/driver/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-settings/driver/feature/CreateAndUpdate")
    ),
  },
  {
    key: "settings.driver.uc",
    path: `${APP_PREFIX_PATH}/aloha-settings/driver/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-settings/driver/feature/CreateAndUpdate")
    ),
  },
  {
    key: "settings.vehicle",
    title: "vehicle",
    path: `${APP_PREFIX_PATH}/aloha-settings/vehicle`,
    component: React.lazy(() => import("views/app-views/aloha-settings/vehicle")),
  },
  {
    key: "settings.vehicle.uc",
    path: `${APP_PREFIX_PATH}/aloha-settings/vehicle/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-settings/vehicle/feature/CreateAndUpdate")
    ),
  },
  {
    key: "settings.vehicle.uc",
    path: `${APP_PREFIX_PATH}/aloha-settings/vehicle/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-settings/vehicle/feature/CreateAndUpdate")
    ),
  },
  {
    key: "settings.employee",
    title: "employee",
    path: `${APP_PREFIX_PATH}/aloha-settings/employee`,
    component: React.lazy(() => import("views/app-views/aloha-settings/employee")),
  },
  {
    key: "settings.employee.uc",
    path: `${APP_PREFIX_PATH}/aloha-settings/employee/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-settings/employee/feature/CreateAndUpdate")
    ),
  },
  {
    key: "settings.employee.uc",
    path: `${APP_PREFIX_PATH}/aloha-settings/employee/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-settings/employee/feature/CreateAndUpdate")
    ),
  },
  {
    key: "settings.customer",
    title: "customer",
    path: `${APP_PREFIX_PATH}/aloha-settings/customer`,
    component: React.lazy(() => import("views/app-views/aloha-settings/customer")),
  },
  {
    key: "settings.setting-system",
    title: "setting-system",
    path: `${APP_PREFIX_PATH}/aloha-settings/setting-system/*`,
    component: React.lazy(() => import("views/app-views/aloha-settings/setting-system")),
  },

  {
    key: "collaborator.report",
    title: "report",
    path: `${APP_PREFIX_PATH}/aloha-collaborator/report`,
    component: React.lazy(() => import("views/app-views/aloha-collaborator/report")),
  },

  {
    key: "collaborator.manage",
    title: "collaborator",
    path: `${APP_PREFIX_PATH}/aloha-collaborator/collaborator`,
    component: React.lazy(() => import("views/app-views/aloha-collaborator/collaborator")),
  },
  {
    key: "collaborator.manage.uc",
    path: `${APP_PREFIX_PATH}/aloha-collaborator/collaborator/uc`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-collaborator/collaborator/feature/CreateAndUpdate")
    ),
  },
  {
    key: "collaborator.manage.uc",
    path: `${APP_PREFIX_PATH}/aloha-collaborator/collaborator/uc/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-collaborator/collaborator/feature/CreateAndUpdate")
    ),
  },
  {
    key: "collaborator.manage.uc",
    path: `${APP_PREFIX_PATH}/aloha-collaborator/collaborator/detail/:id`,
    title: "",
    component: React.lazy(() =>
      import("views/app-views/aloha-collaborator/collaborator/feature/Details")
    ),
  },

  //
  {
    key: "collaborator.payment",
    title: "collaborator",
    path: `${APP_PREFIX_PATH}/aloha-collaborator/payment`,
    component: React.lazy(() => import("views/app-views/aloha-collaborator/payment")),
  },
  {
    key: "collaborator.payment.uc",
    path: `${APP_PREFIX_PATH}/aloha-collaborator/payment/detail/:id`,
    title: "collaborator-details",
    component: React.lazy(() =>
      import("views/app-views/aloha-collaborator/payment/feature/Details")
    ),
  },

  //
  {
    key: "collaborator.setting",
    title: "collaborator-setting",
    path: `${APP_PREFIX_PATH}/aloha-collaborator/setting`,
    component: React.lazy(() => import("views/app-views/aloha-collaborator/setting")),
  },
];
