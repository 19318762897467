import ShiftDriverService from "services/ShiftDriverService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import Utils from "utils";
import openNotification from "utils/notification";

export const getListShiftMonthInfo = createAsyncThunk(
    "shiftDriver/getListShiftMonthInfo",
    async (year, { rejectWithValue }) => {
        try {
            const response = await ShiftDriverService.GetListShiftMonthInfo(year);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getListShiftSearchGrid = createAsyncThunk(
    "shiftDriver/getListShiftSearchGrid",
    async (data, { rejectWithValue }) => {
        try {
            // console.log("123",data);
            const response = await ShiftDriverService.getListShiftSearchGrid(data);
            // console.log("response",response.data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getDropdownDriver = createAsyncThunk(
    "shiftDriver/getDropdownDriver",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ShiftDriverService.getDropdownDriver(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const saveUpdateListShiftDriver = createAsyncThunk(
    "shiftDriver/saveUpdateListShiftDriver",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await ShiftDriverService.saveUpdateListShiftDriver(payload);
        if (onSuccess) onSuccess(response);
        openNotification(
          "success",
          Utils.setLocale("admin.settings.popup.notification.success"),
          Utils.setLocale("admin.settings.popup.notification.title")
        );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );

export const getDriverShiftListSearchGrid = createAsyncThunk(
    "shiftDriver/getDriverShiftListSearchGrid",
    async (data, { rejectWithValue }) => {
        try {
            const response = await ShiftDriverService.getDriverShiftListSearchGrid(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const copyShiftDayApi = createAsyncThunk(
    "shiftDriver/copyShiftDayApi",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await ShiftDriverService.copyShiftDayApi(payload);
        if (onSuccess) onSuccess(response);
        openNotification(
            "success",
            Utils.setLocale("admin.settings.popup.notification.success"),
            Utils.setLocale("admin.settings.popup.notification.title")
        );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
);

export const copyShiftMonthApi = createAsyncThunk(
    "shiftDriver/copyShiftMonthApi",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await ShiftDriverService.copyShiftMonthApi(payload);
        if (onSuccess) onSuccess(response);
        openNotification(
            "success",
            Utils.setLocale("admin.settings.popup.notification.success"),
            Utils.setLocale("admin.settings.popup.notification.title")
        );
        return response.data;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
);


const initialState = {
    loading: false,
    listMonthOfYear: [],
    listShiftDriver: [],
    dropdownDriver:[],
    driverShiftList:[],
    countSearchGrid: {},
    countDriverSearchGrid: {},
    isEndOfShift1Today: false,
    isEndOfShift2Today: false
};

const orderSlice = createSlice(
    {
        name: "shiftDriver",
        initialState,
        reducers: {
            showLoading: (state) => {
                state.loading = true;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(getListShiftMonthInfo.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getListShiftMonthInfo.fulfilled, (state, action) => {
                    state.loading = false;
                    state.listMonthOfYear = action.payload;
                })
                .addCase(getListShiftMonthInfo.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getListShiftSearchGrid.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getListShiftSearchGrid.fulfilled, (state, action) => {
                    state.loading = false;
                    state.listShiftDriver = action.payload.shiftDateInfos;
                    state.countSearchGrid = action.payload.countSearchGrid;
                    state.isEndOfShift1Today = action.payload.isEndOfShift1Today;
                    state.isEndOfShift2Today = action.payload.isEndOfShift2Today;
                })
                .addCase(getListShiftSearchGrid.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getDropdownDriver.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getDropdownDriver.fulfilled, (state, action) => {
                    state.loading = false;
                    state.dropdownDriver = action.payload;
                })
                .addCase(getDropdownDriver.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(saveUpdateListShiftDriver.pending, (state) => {
                    state.loading = true;
                })
                .addCase(saveUpdateListShiftDriver.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(saveUpdateListShiftDriver.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getDriverShiftListSearchGrid.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getDriverShiftListSearchGrid.fulfilled, (state, action) => {
                    state.loading = false;
                    state.driverShiftList = action.payload.shiftDriverInfos;
                    state.countDriverSearchGrid = action.payload.countSearchGrid;
                })
                .addCase(getDriverShiftListSearchGrid.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(copyShiftDayApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(copyShiftDayApi.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(copyShiftDayApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(copyShiftMonthApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(copyShiftMonthApi.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(copyShiftMonthApi.rejected, (state, action) => {
                    state.loading = false;
                });
        },
    }
);

export const { showLoading } = orderSlice.actions;

export default orderSlice.reducer;