import axiosInstance from "auth/FetchInterceptor";
import {
    COLLABORATOR_PAYMENT_API, COLLABORATOR_PAYMENT_CHANGE_STATUS,
    SEARCH_COLLABORATOR_PAYMENT, COLLABORATOR_PAYMENT_UPDATE_MANY_STATUS
} from "constants/ApiConstant";
const CollaboratorPaymentService = {};

CollaboratorPaymentService.getCollaboratorPayment = function (data) {
    return axiosInstance.post(SEARCH_COLLABORATOR_PAYMENT, data);
};

CollaboratorPaymentService.getCollaboratorPaymentById = function (id) {
    return axiosInstance.get(`${COLLABORATOR_PAYMENT_API}/${id}`, null);
};

CollaboratorPaymentService.changeStatusCollaboratorPayment = function (data) {
    return axiosInstance.put(COLLABORATOR_PAYMENT_CHANGE_STATUS, data);
};

CollaboratorPaymentService.updateManyStatusCollaboratorPayment = function (data) {
    return axiosInstance.put(COLLABORATOR_PAYMENT_UPDATE_MANY_STATUS, data);
};

CollaboratorPaymentService.delCollaboratorPayment = function (id) {
    return axiosInstance.delete(`${COLLABORATOR_PAYMENT_API}/${id}`, null);
};

export default CollaboratorPaymentService;