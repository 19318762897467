import { LANGUAGE_LOCAL } from "constants/AuthConstant";
import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
} from "constants/ThemeConstant";

export const APP_NAME = "Aloha";
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/aloha-monitor/dashboard`;
export const UNAUTHENTICATED_ENTRY = "/login";
export const AUTHENTICATED_ENTRY_ADMIN = `${APP_PREFIX_PATH}/manager/partner`;

export const URL_WEB = "#";

const locate = () => {
  let lang = "vi";
  const language = localStorage.getItem(LANGUAGE_LOCAL);
  if (language) {
    lang = language;
  }
  return lang;
};

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: locate(),
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  blankLayout: false,
};
