import axiosInstance from "auth/FetchInterceptor";
import { DRIVER_API, DRIVER_CHANGE_STATUS, DRIVER_CREATE, DRIVER_UPDATE, SEARCH_DRIVER } from "constants/ApiConstant";
const DriverService = {};


DriverService.getDriver = function (data) {
    return axiosInstance.post(SEARCH_DRIVER, data);
};
DriverService.getDriverById = function (id) {
    return axiosInstance.get(`${DRIVER_API}/${id}`, null);
};
DriverService.createDriver = function (data) {
    return axiosInstance.post(DRIVER_CREATE, data);
};
DriverService.updateDriver = function (data) {
    return axiosInstance.put(DRIVER_UPDATE, data);
};
DriverService.changeStatusDriver = function (data) {
    return axiosInstance.put(DRIVER_CHANGE_STATUS, data);
};
DriverService.delDriver = function (id) {
    return axiosInstance.delete(`${DRIVER_API}/id?id=${id}`, null);
};

export default DriverService;