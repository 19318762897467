import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import VoucherService from "services/VoucherService";
import Utils from "utils";
import openNotification from "utils/notification";

export const getVoucherApi = createAsyncThunk(
  "voucher/getVoucherApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await VoucherService.voucherSearchGrid(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getVoucherById = createAsyncThunk(
  "voucher/getVoucherById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await VoucherService.getVoucherById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDetailVoucherApi = createAsyncThunk(
  "voucher/getDetailVoucher",
  async (id, { rejectWithValue }) => {
    try {
      const response = await VoucherService.getDetailVoucher(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);


export const addVoucherApi = createAsyncThunk(
  "voucher/addVoucherApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await VoucherService.addVoucher(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateVoucherApi = createAsyncThunk(
  "voucher/updateVoucherApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await VoucherService.updateVoucher(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const delVoucherApi = createAsyncThunk(
  "voucher/delVoucherApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess, id } = data;
      const response = await VoucherService.delVoucher(id);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getVoucherTicketApi = createAsyncThunk(
  "voucher/getVoucherTicketApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await VoucherService.getVoucherTicketApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const voucherTicketExportExelApi = createAsyncThunk(
  "voucher/voucherTicketExportExelApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await VoucherService.voucherTicketExportExelApi(data);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const voucherTicketExportExelHardCopyApi = createAsyncThunk(
  "voucher/voucherTicketExportExelApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await VoucherService.voucherTicketExportExelHardCopyApi(data);
      if (onSuccess) onSuccess(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);


const initialState = {
  loading: false,
  voucherList: [],
  voucherChart: [],
  voucherDetail: {},
  voucherUseDetailList: {},
};
export const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setVoucherDetail: (state) => {
      state.loading = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVoucherApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVoucherApi.fulfilled, (state, action) => {
        state.loading = false;
        state.voucherList = action.payload.data;
        state.voucherChart = action.payload.dataChart;
      })
      .addCase(getVoucherApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getVoucherById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVoucherById.fulfilled, (state, action) => {
        state.loading = false;
        state.voucherDetail = action.payload.voucherDetailDto;
        state.voucherUseDetailList = action.payload.listVoucherTicket;
      })
      .addCase(getVoucherById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addVoucherApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addVoucherApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addVoucherApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateVoucherApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateVoucherApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateVoucherApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(delVoucherApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(delVoucherApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(delVoucherApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getVoucherTicketApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVoucherTicketApi.fulfilled, (state, action) => {
        state.loading = false;
        state.voucherUseDetailList = action.payload;
      })
      .addCase(getVoucherTicketApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(voucherTicketExportExelApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(voucherTicketExportExelApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(voucherTicketExportExelApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDetailVoucherApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetailVoucherApi.fulfilled, (state, action) => {
        state.loading = false;
        state.voucherDetail = action.payload.voucherDetailDto;
      })
      .addCase(getDetailVoucherApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setVoucherDetail } = voucherSlice.actions;

export default voucherSlice.reducer;
