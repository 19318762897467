import axiosInstance from "auth/FetchInterceptor";
import {GET_DISTANCE_MATRIX, GET_PLACE_DETAIL_API, MAP_SEARCH_API, MAP_WORKINGAREA_API, SEARCH_PLACE_API } from "constants/ApiConstant";
const MonitorService = {};

MonitorService.searchApi = function (data) {
  return axiosInstance.post(MAP_SEARCH_API, data);
};

MonitorService.getWorkingAreaApi = function () {
  return axiosInstance.get(MAP_WORKINGAREA_API, null);
};

MonitorService.getSearchPlace = function (input) {
  return axiosInstance.get(`${SEARCH_PLACE_API}/${input}`, null);
};

MonitorService.GetPlaceDetail = function (placeId) {
  return axiosInstance.get(`${GET_PLACE_DETAIL_API}/${placeId}`, null);
};

MonitorService.getDistanceMatrixAsync = function (data) {
  return axiosInstance.post(GET_DISTANCE_MATRIX, data);
};


export default MonitorService;
  