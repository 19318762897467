import axiosInstance from "auth/FetchInterceptor";

import {
    OTHER_SERVICE_API, OTHER_SERVICE_CREATE, OTHER_SERVICE_UPDATE, OTHER_SERVICE_FAVORITE,
    SEARCH_OTHER_SERVICE, OTHER_SERVICE_VIEW_COUNT, OTHER_SERVICE_DETAIL, OTHER_SERVICE_LIKE_COUNT,
    ALL_COMMENT, LIKE_COMMENT, CHANGE_ACTIVE_COMMENT, OTHER_SERVICE_DETAIL_FAVORITE
}
    from "constants/ApiConstant";
const OtherServiceService = {};

OtherServiceService.getListOtherService = function (data) {
    return axiosInstance.post(SEARCH_OTHER_SERVICE, data);
};
OtherServiceService.getOtherServiceById = function (id) {
    return axiosInstance.get(`${OTHER_SERVICE_API}/${id}`, null);
};
OtherServiceService.createOtherService = function (data) {
    return axiosInstance.post(OTHER_SERVICE_CREATE, data);
};
OtherServiceService.updateOtherService = function (data) {
    return axiosInstance.put(OTHER_SERVICE_UPDATE, data);
};
OtherServiceService.delOtherService = function (id) {
    return axiosInstance.delete(`${OTHER_SERVICE_API}/${id}`, null);
};
OtherServiceService.getOtherServiceViewCount = function (id) {
    return axiosInstance.get(`${OTHER_SERVICE_VIEW_COUNT}/${id}`, null);
};
OtherServiceService.getOtherServiceLikeCount = function (id) {
    return axiosInstance.get(`${OTHER_SERVICE_LIKE_COUNT}/${id}`, null);
};
OtherServiceService.getOtherServiceDetail = function (data) {
    const url = `${OTHER_SERVICE_DETAIL}/${data.id}/${data.customerId}/${data.isViewCount}`;
    return axiosInstance.get(url, null);
};
OtherServiceService.getOtherServiceDetailFavorite = function (data) {
    return axiosInstance.get(`${OTHER_SERVICE_DETAIL_FAVORITE}/${data.id}/${data.customerId}`, null);
};
OtherServiceService.FavoriteOtherService = function (data) {
    return axiosInstance.post(`${OTHER_SERVICE_FAVORITE}/${data.id}/${data.customerId}`, data);
};
OtherServiceService.getAllComment = function (id) {
    return axiosInstance.get(`${ALL_COMMENT}/${id}`, null);
};
OtherServiceService.changeActiveComment = function (data) {
    return axiosInstance.put(`${CHANGE_ACTIVE_COMMENT}`, data);
};
OtherServiceService.likeComment = function (id) {
    return axiosInstance.post(`${LIKE_COMMENT}/${id}`, null);
};
export default OtherServiceService;