import axiosInstance from "auth/FetchInterceptor";
import { DRIVER_FEEDBACK_API, DRIVER_FEEDBACK_GOOD, DRIVER_FEEDBACK_BAD } from "constants/ApiConstant";
const DriverFeedbackService = {};


DriverFeedbackService.getListDriverFeedbackGood = function (data) {
    return axiosInstance.get(DRIVER_FEEDBACK_GOOD, data);
};
DriverFeedbackService.getListDriverFeedbackBad = function (data) {
    return axiosInstance.get(DRIVER_FEEDBACK_BAD, data);
};
DriverFeedbackService.getDriverFeedbackById = function (id) {
    return axiosInstance.get(`${DRIVER_FEEDBACK_API}/${id}`, null);
};
DriverFeedbackService.createDriverFeedback = function (data) {
    return axiosInstance.post(DRIVER_FEEDBACK_API, data);
};
DriverFeedbackService.updateDriverFeedback = function (data) {
    return axiosInstance.put(DRIVER_FEEDBACK_API, data);
};
DriverFeedbackService.delDriverFeedback = function (id) {
    return axiosInstance.delete(`${DRIVER_FEEDBACK_API}/${id}`, null);
};

export default DriverFeedbackService;