import axiosInstance from "auth/FetchInterceptor";
import { PRICE_SETTING_API, PRICE_SETTING_CREATE, PRICE_SETTING_UPDATE, SEARCH_PRICE_SETTING, PRICE_SETTING_CHANGE_STATUS } from "constants/ApiConstant";
const PriceSettingService = {};

PriceSettingService.getPriceSetting = function (data) {
    return axiosInstance.post(SEARCH_PRICE_SETTING, data);
};

PriceSettingService.getPriceSettingById = function (id) {
    return axiosInstance.get(`${PRICE_SETTING_API}/${id}`, null);
};

PriceSettingService.createPriceSetting = function (data) {
    return axiosInstance.post(PRICE_SETTING_CREATE, data);
};

PriceSettingService.updatePriceSetting = function (data) {
    return axiosInstance.put(PRICE_SETTING_UPDATE, data);
};

PriceSettingService.changeStatusPriceSetting = function (data) {
    return axiosInstance.put(PRICE_SETTING_CHANGE_STATUS, data);
};

PriceSettingService.delPriceSetting = function (id) {
    return axiosInstance.delete(`${PRICE_SETTING_API}/id?id=${id}`, null);
};

export default PriceSettingService;