import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CustomerService from "services/CustomerService";
import openNotification from "utils/notification";
import Utils from "utils";

export const getCustomerApi = createAsyncThunk(
  "customer/getCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const response = await CustomerService.getCustomer(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getCustomerById = createAsyncThunk(
  "customer/getCustomerById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await CustomerService.getCustomerById(id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const changeStatusCustomer = createAsyncThunk(
  "customer/changeStatusCustomer",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const response = await CustomerService.changeStatusCustomer(data);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      if (onSuccess) onSuccess(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

const initialState = {
  loading: false,
  customerList: [],
  customerDetail: {},
};
export const storeCustomerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    setCustomer: (state, action) => {
      state.customerList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerApi.fulfilled, (state, action) => {
        state.loading = false;
        state.customerList = action.payload;
      })
      .addCase(getCustomerApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCustomerById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        state.loading = false;
        state.customerDetail = action.payload;
      })
      .addCase(getCustomerById.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(changeStatusCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeStatusCustomer.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(changeStatusCustomer.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setCustomer } = storeCustomerSlice.actions;

export default storeCustomerSlice.reducer;
