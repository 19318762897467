import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import CityTourService from "services/CityTourService";
import Utils from "utils";
import openNotification from "utils/notification";

export const getCityTourApi = createAsyncThunk(
    "cityTour/getCityTourApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CityTourService.getListCityTour(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getDropdownPlace = createAsyncThunk(
    "cityTour/getDropdownPlace",
    async ({ rejectWithValue }) => {
        try {
            const response = await CityTourService.getDropdownPlace();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getCityTourById = createAsyncThunk(
    "cityTour/getCityTourById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await CityTourService.getCityTourById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const addCityTourApi = createAsyncThunk(
    "cityTour/addCityTourApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CityTourService.createCityTour(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateCityTourApi = createAsyncThunk(
    "cityTour/updateCityTourApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CityTourService.updateCityTour(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delCityTourApi = createAsyncThunk(
    "cityTour/delCityTourApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const response = await CityTourService.delCityTour(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


const initialState = {
    loading: false,
    cityTourList: [],
    cityTourDetail: {},
    placeList: [],
};
export const cityTourSlice = createSlice({
    name: "cityTour",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setCityTourDetail: (state) => {
            state.loading = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCityTourApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCityTourApi.fulfilled, (state, action) => {
                state.loading = false;
                state.cityTourList = action.payload;
            })
            .addCase(getCityTourApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getDropdownPlace.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDropdownPlace.fulfilled, (state, action) => {
                state.loading = false;
                state.placeList = action.payload;
            })
            .addCase(getDropdownPlace.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCityTourById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCityTourById.fulfilled, (state, action) => {
                state.loading = false;
                state.cityTourDetail = action.payload;
            })
            .addCase(getCityTourById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addCityTourApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(addCityTourApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addCityTourApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCityTourApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCityTourApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCityTourApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delCityTourApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delCityTourApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delCityTourApi.rejected, (state, action) => {
                state.loading = false;
            })

    },
});

export const { showLoading, setCityTourDetail } = cityTourSlice.actions;

export default cityTourSlice.reducer;