import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import OtherSettingService from "services/OtherSettingService";
import Utils from "utils";
import openNotification from "utils/notification";

export const saveSystemSettingApi = createAsyncThunk(
    "otherSetting/saveUpdateListShiftDriver",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OtherSettingService.saveSystemSettingApi(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const saveNotifyTimeApi = createAsyncThunk(
    "otherSetting/saveNotifyTime",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OtherSettingService.saveNotifyTimeApi(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getSystemSettingOthers = createAsyncThunk(
    "otherSetting/getSystemSettingOthers",
    async (data, { rejectWithValue }) => {
        try {
            const response = await OtherSettingService.getSystemSettingOthers();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getCancelReason = createAsyncThunk(
    "otherSetting/getCancelReason",
    async ({ rejectWithValue }) => {
        try {
            const response = await OtherSettingService.getCancelReason();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getRejectReason = createAsyncThunk(
    "otherSetting/getRejectReason",
    async ({ rejectWithValue }) => {
        try {
            const response = await OtherSettingService.getRejectReason();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getOfflineReason = createAsyncThunk(
    "otherSetting/getOfflineReason",
    async ({ rejectWithValue }) => {
        try {
            const response = await OtherSettingService.getOfflineReason();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const addReason = createAsyncThunk(
    "otherSetting/addReason",
    async (data, { rejectWithValue }) => {
        const { onSuccess } = data;
        try {
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OtherSettingService.createReason(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateReason = createAsyncThunk(
    "otherSetting/updateReason",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OtherSettingService.updateReason(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const deleteReason = createAsyncThunk(
    "otherSetting/delReason",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OtherSettingService.delReason(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getShift = createAsyncThunk(
    "otherSetting/getShift",
    async ({ rejectWithValue }) => {
        try {
            const response = await OtherSettingService.getShift();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateShift = createAsyncThunk(
    "otherSetting/updateShift",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OtherSettingService.updateShift(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    systemSettingOther: {},
    rejectReason: [],
    cancelReason: [],
    offlineReason: [],
    listShift: [],
};

export const OtherSettingSystem = createSlice({
    name: "other-setting",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setRejectReason: (state, { payload }) => {
            state.rejectReason = payload;
        },
        setCancelReason: (state, { payload }) => {
            state.cancelReason = payload;
        },
        setOfflineReason: (state, { payload }) => {
            state.offlineReason = payload;
        },
        setShift: (state, { payload }) => {
            state.listShift = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(saveSystemSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveSystemSettingApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(saveSystemSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(saveNotifyTimeApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveNotifyTimeApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(saveNotifyTimeApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getSystemSettingOthers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSystemSettingOthers.fulfilled, (state, action) => {
                state.loading = false;
                state.systemSettingOther = action.payload;
            })
            .addCase(getSystemSettingOthers.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCancelReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCancelReason.fulfilled, (state, action) => {
                state.loading = false;
                state.cancelReason = action.payload;
            })
            .addCase(getCancelReason.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getRejectReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(getRejectReason.fulfilled, (state, action) => {
                state.loading = false;
                state.rejectReason = action.payload;
            })
            .addCase(getRejectReason.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getOfflineReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOfflineReason.fulfilled, (state, action) => {
                state.loading = false;
                state.offlineReason = action.payload;
            })
            .addCase(getOfflineReason.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(addReason.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addReason.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateReason.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateReason.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteReason.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteReason.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getShift.pending, (state) => {
                state.loading = true;
            })
            .addCase(getShift.fulfilled, (state, action) => {
                state.loading = false;
                state.listShift = action.payload;
            })
            .addCase(getShift.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateShift.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateShift.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateShift.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setRejectReason, setCancelReason, setOfflineReason, setShift } = OtherSettingSystem.actions;
export default OtherSettingSystem.reducer;