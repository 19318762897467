import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { 
	AUTH_PREFIX_PATH, 
	UNAUTHENTICATED_ENTRY, 
	REDIRECT_URL_KEY 
} from 'configs/AppConfig';
import dayjs from 'dayjs';
import { EXPIRE_TIME } from 'constants/AuthConstant';
import store from '../store';
import { signOutSuccess } from 'store/slices/authSlice';
import { notification } from 'antd';
import { useState } from 'react';

const ProtectedRoute = () => {
	const { token,  } = useSelector(state => state.auth)
	const expiresTime = localStorage.getItem(EXPIRE_TIME);
	// const expiresTime = 1691117665;
	const location = useLocation();
	const [isRunning, setIsRunning] = useState(false);

	const formatDate = 'YYYY-MM-DD HH:mm';
	const now = dayjs().add(3, 'm');
	const expiresFormat = dayjs(expiresTime * 1000).format(formatDate);
	const expiresMoment = dayjs(expiresFormat, formatDate);


	let isNeedRefresh = false;

	if(!(expiresMoment.isAfter(now) || expiresMoment.isSame(now))){
		isNeedRefresh = true;
	}
	
	let notificationParam = {
		message: "",
	};
	if(isNeedRefresh && !isRunning && token){
		setIsRunning(true)
		notificationParam.message = "Phiên đăng nhập hết hạn";
		notificationParam.description = "Xin vui lòng đăng nhập lại";
		store.dispatch(signOutSuccess());
		notification.error(notificationParam);
	}
	if (!token) {
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	}

	return <Outlet />
}

export default ProtectedRoute