import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MonitorService from "services/MonitorService";

export const monitorSearchApi = createAsyncThunk(
  "monitor/searchApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MonitorService.searchApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getAllWorkingAreaApi = createAsyncThunk(
  "monitor/getWorkingArea",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MonitorService.getWorkingAreaApi();
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getSearchPlace = createAsyncThunk(
  "monitor/getSearchPlace",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MonitorService.getSearchPlace(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetPlaceDetail = createAsyncThunk(
  "monitor/getSearchPlace",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MonitorService.GetPlaceDetail(data);
      // console.log("response",response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDistanceMatrixAsync = createAsyncThunk(
  "monitor/getDistanceMatrixAsync",
  async (data, { rejectWithValue }) => {
    try {
      const response = await MonitorService.getDistanceMatrixAsync(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);



const initialState = {
  loading: false,
  vehicleDrivers: [],
  statusData: [],
  workingArea: [],
};
export const monitorSlice = createSlice({
  name: "monitor",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(monitorSearchApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(monitorSearchApi.fulfilled, (state, action) => {
        state.loading = false;
        const { statusData, vehicleDrivers } = action.payload;
        state.vehicleDrivers = vehicleDrivers;
        state.statusData = statusData;
      })
      .addCase(monitorSearchApi.rejected, (state, action) => {
        state.loading = false;
      });
    builder
      .addCase(getAllWorkingAreaApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllWorkingAreaApi.fulfilled, (state, action) => {
        state.loading = false;
        state.workingArea = action.payload;
      })
      .addCase(getAllWorkingAreaApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = monitorSlice.actions;

export default monitorSlice.reducer;
