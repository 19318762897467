import axiosInstance from "auth/FetchInterceptor";
import { ADD_MANUAL_BOOKING, DROPDOWN_VEHICLE_DRIVER, DELETE_MANUAL_BOOKING, GET_ORDER_DETAIL, GET_ORDER_HISTORY, SEARCH_ORDER, UPDATE_MANUAL_BOOKING } from "constants/ApiConstant";
const OrderService = {};


OrderService.orderSearchApi = function (data) {
  return axiosInstance.post(SEARCH_ORDER, data);
};
OrderService.getDropdownVehicleDriver = function (data) {
  return axiosInstance.post(DROPDOWN_VEHICLE_DRIVER, data);
};
OrderService.addManualBookingApi = function (data) {
  return axiosInstance.post(ADD_MANUAL_BOOKING, data);
};
OrderService.updateManualBookingApi = function (data) {
  return axiosInstance.post(UPDATE_MANUAL_BOOKING, data);
};
OrderService.deleteManualBookingApi = function (data) {
  return axiosInstance.post(DELETE_MANUAL_BOOKING, data);
};
OrderService.GetOrderDetail = function (orderId) {
  return axiosInstance.get(`${GET_ORDER_DETAIL}/${orderId}`, null);
};
OrderService.orderHistoryApi = function (orderId) {
  return axiosInstance.get(`${GET_ORDER_HISTORY}/${orderId}`, null);
};

export default OrderService;
