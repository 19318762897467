import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import OrderService from 'services/OrderCustomerService';


export const getOrderCustomerApi = createAsyncThunk(
    "order/getOrderCustomerApi",
    async ({ data, customerId }, { rejectWithValue }) => {
        try {
            const response = await OrderService.getOrderCustomer(data, customerId);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const orderExportExcelApi = createAsyncThunk(
    "store/orderExportExcelApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderService.OrderExportExel(data);
            if (onSuccess) onSuccess(response);
            return response;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


const initialState = {
    loading: false,
    orderList: [],
};

const orderSlice = createSlice(
    {
        name: "order",
        initialState,
        reducers: {
            showLoading: (state) => {
                state.loading = true;
            },
            setOrder: (state, action) => {
                state.orderList = action.payload;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(getOrderCustomerApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getOrderCustomerApi.fulfilled, (state, action) => {
                    state.loading = false;
                    state.orderList = action.payload;
                })
                .addCase(getOrderCustomerApi.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(orderExportExcelApi.pending, (state) => {
                    state.loading = true;
                })
                .addCase(orderExportExcelApi.fulfilled, (state, action) => {
                    state.loading = false;
                })
                .addCase(orderExportExcelApi.rejected, (state, action) => {
                    state.loading = false;
                });;
        },
    }
);

export const { showLoading } = orderSlice.actions;

export default orderSlice.reducer;
