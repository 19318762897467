import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import CollaboratorPaymentService from "services/CollaboratorPaymentService";

export const getCollaboratorPaymentApi = createAsyncThunk(
    "collaboratorPayment/getCollaboratorPayment",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CollaboratorPaymentService.getCollaboratorPayment(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getCollaboratorPaymentById = createAsyncThunk(
    "collaboratorPayment/getCollaboratorPaymentById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await CollaboratorPaymentService.getCollaboratorPaymentById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const changeStatusCollaboratorPaymentApi = createAsyncThunk(
    "collaboratorPayment/changeStatusCollaboratorPayment",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorPaymentService.changeStatusCollaboratorPayment(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateManyStatusCollaboratorPayment = createAsyncThunk(
    "collaboratorPayment/updateManyStatusCollaboratorPayment",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorPaymentService.updateManyStatusCollaboratorPayment(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delCollaboratorPaymentApi = createAsyncThunk(
    "collaboratorPayment/delCollaboratorPaymentApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorPaymentService.delCollaboratorPayment(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    listCollaboratorPayment: [],
    collaboratorPaymentDetail: {},
};

export const storeCollaboratorPaymentSlice = createSlice({
    name: "collaboratorPayment",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setCollaboratorPayment: (state, action) => {
            state.collaboratorPaymentList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCollaboratorPaymentApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCollaboratorPaymentApi.fulfilled, (state, action) => {
                state.loading = false;
                state.listCollaboratorPayment = action.payload;
            })
            .addCase(getCollaboratorPaymentApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCollaboratorPaymentById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCollaboratorPaymentById.fulfilled, (state, action) => {
                state.loading = false;
                state.collaboratorPaymentDetail = action.payload;
            })
            .addCase(getCollaboratorPaymentById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(changeStatusCollaboratorPaymentApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeStatusCollaboratorPaymentApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(changeStatusCollaboratorPaymentApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateManyStatusCollaboratorPayment.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateManyStatusCollaboratorPayment.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateManyStatusCollaboratorPayment.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delCollaboratorPaymentApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delCollaboratorPaymentApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delCollaboratorPaymentApi.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setCollaboratorPayment } = storeCollaboratorPaymentSlice.actions;

export default storeCollaboratorPaymentSlice.reducer;
