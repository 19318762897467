import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import newsService from "services/NewsService";
import { cloneDeep } from "lodash";
import Utils from "utils";
import openNotification from "utils/notification";

export const getNewsDetailApi = createAsyncThunk(
    "news/detail",
    async (data, { rejectWithValue }) => {
        try {
            const response = await newsService.getNewDetail(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getNewsApi = createAsyncThunk(
    "news/getNewsApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await newsService.getListNews(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getNewsById = createAsyncThunk(
    "news/getNewsById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await newsService.getNewsById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getNewsViewCount = createAsyncThunk(
    "news/getNewsViewCount",
    async (id, { rejectWithValue }) => {
        try {
            const response = await newsService.getNewsViewCount(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const addNewsApi = createAsyncThunk(
    "news/addNewsApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await newsService.createNews(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateNewsApi = createAsyncThunk(
    "news/updateNewsApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await newsService.updateNews(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delNewsApi = createAsyncThunk(
    "news/delNewsApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const response = await newsService.delNews(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    newsList: [],
    listViewCount: [],
    newsDetail: {}
};
export const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setNewsDetail: (state) => {
            state.loading = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNewsViewCount.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNewsViewCount.fulfilled, (state, action) => {
                state.loading = false;
                state.listViewCount = action.payload;
            })
            .addCase(getNewsViewCount.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getNewsApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNewsApi.fulfilled, (state, action) => {
                state.loading = false;
                state.newsList = action.payload;
            })
            .addCase(getNewsApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getNewsById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNewsById.fulfilled, (state, action) => {
                state.loading = false;
                state.newsDetail = action.payload;
            })
            .addCase(getNewsById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addNewsApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(addNewsApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addNewsApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateNewsApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateNewsApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateNewsApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delNewsApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delNewsApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delNewsApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getNewsDetailApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNewsDetailApi.fulfilled, (state, action) => {
                state.loading = false;
                state.newsDetail = action.payload;
            })
            .addCase(getNewsDetailApi.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setNewsDetail } = newsSlice.actions;

export default newsSlice.reducer;