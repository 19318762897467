import Qs from 'qs';
// import Config from 'react-native-config';
// import UtilsService from '~utils/services/utilsService';
// import {LatLng} from 'react-native-maps';
import isEmpty from 'lodash/isEmpty';

class GooglePlacesServices {
    constructor() {
        this.url = 'https://maps.googleapis.com/maps/api';
        this.timeoutRequest = 20000;
    }

    /**
     * Search by keyword using google auto complete search
     * @param keyword: string
     * @param query: object.
     * {
     *   radius: 20000 (Defines the distance - in meters)
     *   location: '20.955636961014246,107.04956051205747',
     *   strictbounds: true,
     *   key: Config.GOOGLE_MAPS_API_KEY,
     *   language: 'vi',
     *   components: 'country:VN',
     * }
     * @param currLatLng: object
     * {
     *   latitude: number;
     *   longitude: number;
     * }
     * @param userLocation: object
     * {
     *   latitude: number;
     *   longitude: number;
     * }
     * */

    getAutoCompleteSearch = (
        keyword,
        query,
        currLatLng,
        userLocation,
    ) => {
        return new Promise(resolve => {
            const request = new XMLHttpRequest();
            request.timeout = 20000;
            request.onload = async () => {
                if (request.readyState !== 4) {
                    return;
                }

                if (request.status === 200) {
                    const responseJSON = JSON.parse(request.responseText);
                    if (typeof responseJSON.predictions !== 'undefined') {
                        const results = responseJSON.predictions;

                        const promiseAll = [];
                        results.map(item => {
                            return promiseAll.push(
                                googlePlacesServices
                                    .getPlaceDetail(item.place_id, userLocation)
                                    .then(data => {
                                        return data;
                                    }),
                            );
                        });
                        const newSource = await Promise.all(promiseAll);
                        resolve(newSource);
                    }
                    if (typeof responseJSON.error_message !== 'undefined') {
                        resolve(null);
                    }
                } else {
                    // console.warn("google places autocomplete: request could not be completed or has been aborted");
                    resolve(null);
                }
            };
            const url = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input=amoeba&types=establishment&location=37.76999%2C-122.44696&radius=500&key=AIzaSyCc_-8wCUhz5PCiXrKxPKfUXmKkyKkjzUo'
            request.open(
                'GET',
                url
                // `${this.url}/place/autocomplete/json?input=thanh hoa` +
                //encodeURIComponent(keyword) +
                //'&key=AIzaSyCc_-8wCUhz5PCiXrKxPKfUXmKkyKkjzUo'
                // Qs.stringify(query),
            );

            request.withCredentials = true;
            // setRequestHeaders(request, getRequestHeaders(props.requestUrl));

            request.send();
        });
    };

    getPlaceDetail = (placeIds, currLatLng) => {
        return new Promise(resolve => {
            const request = new XMLHttpRequest();
            // this._requests.push(request);
            request.timeout = 20000;
            // request.ontimeout = props.onTimeout;
            request.onload = () => {
                // if (request.readyState !== 4) {
                //   resolve(null);
                //   return;
                // }

                if (request.status === 200) {
                    const responseJSON = JSON.parse(request.responseText);

                    if (responseJSON.status === 'OK') {
                        const details = responseJSON.result;
                        // _disableRowLoaders();
                        // _onBlur();
                        // setStateText(_renderDescription(rowData));
                        // delete rowData.isLoading;
                        // props.onPress(rowData, details);
                        if (!isEmpty(currLatLng)) {
                            //   details.distanceKm = UtilsService.getDistanceFromLatLonInKm(
                            //     currLatLng.latitude,
                            //     currLatLng.longitude,
                            //     details.geometry.location.lat,
                            //     details.geometry.location.lng,
                            //   );
                        }
                        resolve(details);
                    } else {
                        // _disableRowLoaders();
                        // if (props.autoFillOnNotFound) {
                        //   setStateText(_renderDescription(rowData));
                        //   delete rowData.isLoading;
                        // }
                        //
                        // if (!props.onNotFound) {
                        //   console.warn(
                        //     'google places autocomplete: ' + responseJSON.status,
                        //   );
                        // } else {
                        //   props.onNotFound(responseJSON);
                        // }

                        resolve(null);
                    }
                } else {
                    console.log('ERROR');
                    resolve(null);
                    // _disableRowLoaders();

                    // if (!props.onFail) {
                    //   console.warn(
                    //     'google places autocomplete: request could not be completed or has been aborted',
                    //   );
                    // } else {
                    //   props.onFail('request could not be completed or has been aborted');
                    // }
                }
            };

            request.open(
                'GET',
                `${this.url}/place/details/json?` +
                Qs.stringify({
                    key: process.env.REACT_APP_API_GMAP_API_KEY,
                    placeid: placeIds,
                    language: 'vi',
                    components: 'country:VN',
                    fields: 'formatted_address,name,geometry,place_id',
                    // fields: 'formatted_address,name,geometry,address_components',
                    // key: props.query.key,
                    // placeid: rowData.place_id,
                    // language: props.query.language,
                    // ...props.GooglePlacesDetailsQuery,
                }),
            );

            request.withCredentials = true;
            // setRequestHeaders(request, getRequestHeaders(props.requestUrl));

            request.send();
        });
    };

    getAddressFromLatLng = (
        latLng,
        isGetPlaceDetail,
        currLatLng,
    ) => {
        return new Promise(resolve => {
            const request = new XMLHttpRequest();
            // this._requests.push(request);
            request.timeout = this.timeoutRequest;
            // request.ontimeout = props.onTimeout;
            request.onload = async () => {
                // if (request.readyState !== 4) {
                //   resolve(null);
                //   return;
                // }

                if (request.status === 200) {
                    const responseJSON = JSON.parse(request.responseText);

                    if (responseJSON.status === 'OK') {
                        const details = responseJSON.results[0];
                        // _disableRowLoaders();
                        // _onBlur();
                        // setStateText(_renderDescription(rowData));
                        // delete rowData.isLoading;
                        // props.onPress(rowData, details);
                        if (isGetPlaceDetail) {
                            const placeDetail = await this.getPlaceDetail(
                                details.place_id,
                                currLatLng,
                            );
                            resolve(placeDetail);
                        } else {
                            resolve(details);
                        }
                    } else {
                        // _disableRowLoaders();
                        // if (props.autoFillOnNotFound) {
                        //   setStateText(_renderDescription(rowData));
                        //   delete rowData.isLoading;
                        // }
                        //
                        // if (!props.onNotFound) {
                        //   console.warn(
                        //     'google places autocomplete: ' + responseJSON.status,
                        //   );
                        // } else {
                        //   props.onNotFound(responseJSON);
                        // }

                        resolve(null);
                    }
                } else {
                    console.log('ERROR');
                    resolve(null);
                    // _disableRowLoaders();

                    // if (!props.onFail) {
                    //   console.warn(
                    //     'google places autocomplete: request could not be completed or has been aborted',
                    //   );
                    // } else {
                    //   props.onFail('request could not be completed or has been aborted');
                    // }
                }
            };

            request.open(
                'GET',
                `${this.url}/geocode/json?` +
                Qs.stringify({
                    key: process.env.REACT_APP_API_GMAP_API_KEY,
                    language: 'vi',
                    latlng: `${latLng.latitude},${latLng.longitude}`,
                    fields: 'formatted_address,place_id,geometry',
                }),
            );

            request.withCredentials = true;
            // setRequestHeaders(request, getRequestHeaders(props.requestUrl));

            request.send();
        });
    };

    getDistanceMatrix = (
        origin,
        destination,
        otherParams,
    ) => {
        return new Promise(resolve => {
            const request = new XMLHttpRequest();
            // this._requests.push(request);
            request.timeout = this.timeoutRequest;
            // request.ontimeout = props.onTimeout;
            request.onload = async () => {
                // if (request.readyState !== 4) {
                //   resolve(null);
                //   return;
                // }

                // {
                //   "distance": {
                //   "text": "0,7 km",
                //     "value": 701
                // },
                //   "duration": {
                //   "text": "2 phút",
                //     "value": 141
                // },
                //   "status": "OK"
                // }

                if (request.status === 200) {
                    const responseJSON = JSON.parse(request.responseText);

                    if (responseJSON.status === 'OK' && !isEmpty(responseJSON.rows[0])) {
                        const details = responseJSON.rows[0].elements[0];
                        // Other params to matching
                        details.otherParams = otherParams;
                        resolve(details);
                    } else {
                        resolve(null);
                    }
                } else {
                    console.log('ERROR');
                    resolve(null);
                }
            };

            request.open(
                'GET',
                `${this.url}/distancematrix/json?` +
                Qs.stringify({
                    key: process.env.REACT_APP_API_GMAP_API_KEY,
                    language: 'vi',
                    origins: `${origin.latitude},${origin.longitude}`,
                    destinations: `${destination.latitude},${destination.longitude}`,
                    // fields: 'formatted_address,place_id,geometry',
                }),
            );

            request.withCredentials = true;
            // setRequestHeaders(request, getRequestHeaders(props.requestUrl));

            request.send();
        });
    };

    getPhotoFromSearch = (
        photoReference,
        maxWidth,
        maxHeight,
    ) => {
        return new Promise(resolve => {
            // _abortRequests();
            const request = new XMLHttpRequest();
            // this._requests.push(request);
            request.timeout = this.timeoutRequest;
            // request.ontimeout = props.onTimeout;
            request.onload = async () => {
                resolve({
                    imageUrl: request.responseURL,
                    photoReference: photoReference,
                });
                // if (request.readyState !== 4) {
                //   return;
                // }

                // if (request.status === 200) {
                //   const responseJSON = JSON.parse(request.responseText);
                //   if (typeof responseJSON.results !== 'undefined') {
                //     const results = responseJSON.results;
                //     resolve(results);
                //   }
                //   if (typeof responseJSON.error_message !== 'undefined') {
                //     // if (!props.onFail) {
                //     //   console.warn(
                //     //     'google places autocomplete: ' + responseJSON.error_message,
                //     //   );
                //     // } else {
                //     //   props.onFail(responseJSON.error_message);
                //     // }
                //     resolve(null);
                //   }
                // } else {
                //   // console.warn("google places autocomplete: request could not be completed or has been aborted");
                // }
            };

            // const requestUrl =
            //   `${this.url}/place/photo?` +
            //   Qs.stringify({
            //     // key: Config.GOOGLE_MAPS_API_KEY,
            //     // language: 'vi',
            //     photo_reference: photoReference,
            //   });
            // const requestUrl = `${this.url}/place/photo?maxwidth=${maxWidth}&maxheight=${maxHeight}&photo_reference=${photoReference}&key=${Config.GOOGLE_MAPS_API_KEY}`;
            let requestUrl = `${this.url}/place/photo?photo_reference=${photoReference}&key=${process.env.REACT_APP_API_GMAP_API_KEY}`;
            if (typeof maxWidth === 'number') {
                requestUrl += `&maxwidth=${maxWidth}`;
            }
            if (typeof maxHeight === 'number') {
                requestUrl += `&maxheight=${maxHeight}`;
            }

            request.open('GET', requestUrl);
            request.withCredentials = true;
            // setRequestHeaders(request, getRequestHeaders(props.requestUrl));

            request.send();
        });
    };

    getNearBySearch = (
        latitude,
        longitude,
        isGetPhoto,
        isGetDetail,
    ) => {
        return new Promise(resolve => {
            // _abortRequests();
            const request = new XMLHttpRequest();
            // this._requests.push(request);
            request.timeout = this.timeoutRequest;
            // request.ontimeout = props.onTimeout;
            request.onload = async () => {
                // if (request.readyState !== 4) {
                //   return;
                // }

                if (request.status === 200) {
                    const responseJSON = JSON.parse(request.responseText);
                    if (typeof responseJSON.results !== 'undefined') {
                        const rawSource = responseJSON.results;
                        rawSource.forEach(item => {
                            //   item.distanceKm = UtilsService.getDistanceFromLatLonInKm(
                            //     latitude,
                            //     longitude,
                            //     item.geometry.location.lat,
                            //     item.geometry.location.lng,
                            //   );
                        });
                        rawSource.sort((a, b) => a.distanceKm - b.distanceKm);
                        const results = rawSource.slice(0, 7);
                        if (isGetPhoto) {
                            const promiseAll = [];
                            const promiseGetDetailAll = [];
                            results.forEach(item => {
                                // item.distanceKm = UtilsService.getDistanceFromLatLonInKm(
                                //   latitude,
                                //   longitude,
                                //   item.geometry.location.lat,
                                //   item.geometry.location.lng,
                                // );

                                if (isGetPhoto) {
                                    const photoItem = isEmpty(item.photos)
                                        ? null
                                        : item.photos[0];
                                    if (photoItem) {
                                        promiseAll.push(
                                            this.getPhotoFromSearch(
                                                photoItem.photo_reference,
                                                photoItem.width,
                                                photoItem.height,
                                            ).then(data => {
                                                return data;
                                            }),
                                        );
                                    }
                                }

                                if (isGetDetail) {
                                    promiseGetDetailAll.push(this.getPlaceDetail(item.place_id));
                                }
                            });

                            let placeDetails, photoList;
                            if (isGetDetail) {
                                placeDetails = await Promise.all(promiseGetDetailAll);
                            }

                            if (isGetPhoto) {
                                photoList = await Promise.all(promiseAll);
                            }
                            results.forEach(item => {
                                item.photoUrl = null;
                                if (
                                    isGetPhoto &&
                                    Array.isArray(photoList) &&
                                    photoList.length > 0
                                ) {
                                    const photoItem = isEmpty(item.photos)
                                        ? null
                                        : item.photos[0];
                                    if (photoItem) {
                                        const foundPhoto = photoList.find(
                                            x => x.photoReference === photoItem.photo_reference,
                                        );
                                        if (foundPhoto) {
                                            item.photoUrl = foundPhoto.imageUrl;
                                        }
                                    }
                                }

                                if (
                                    isGetDetail &&
                                    Array.isArray(placeDetails) &&
                                    placeDetails.length > 0
                                ) {
                                    const foundPlace = placeDetails.find(
                                        x => x.place_id === item.place_id,
                                    );
                                    if (foundPlace) {
                                        item.fullAddress = foundPlace.formatted_address;
                                    }
                                }
                            });
                            resolve(results);
                        } else {
                            resolve(results);
                        }
                    }
                    if (typeof responseJSON.error_message !== 'undefined') {
                        // if (!props.onFail) {
                        //   console.warn(
                        //     'google places autocomplete: ' + responseJSON.error_message,
                        //   );
                        // } else {
                        //   props.onFail(responseJSON.error_message);
                        // }
                        resolve(null);
                    }
                } else {
                    // console.warn("google places autocomplete: request could not be completed or has been aborted");
                }
            };

            const requestUrl =
                `${this.url}/place/nearbysearch/json?` +
                Qs.stringify({
                    key: process.env.REACT_APP_API_GMAP_API_KEY,
                    language: 'vi',
                    location: `${latitude},${longitude}`,
                    radius: 1500,
                    // fields: 'formatted_address,place_id,geometry',

                    // location: latitude + ',' + longitude,
                    // key: props.query.key,
                    // ...props.GooglePlacesSearchQuery,
                });
            request.open('GET', requestUrl);

            request.withCredentials = true;
            // setRequestHeaders(request, getRequestHeaders(props.requestUrl));

            request.send();
        });
    };
}

const googlePlacesServices = new GooglePlacesServices();

export default googlePlacesServices;
