import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import SettingHideOrderService from "services/SettingHideOrderService";

export const getAllSettingHideOrderApi = createAsyncThunk(
    "settingHideOrder/getSettingHideOrder",
    async (data, { rejectWithValue }) => {
        try {
            const response = await SettingHideOrderService.getAllSettingHideOrder(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const saveSettingHideOrderApi = createAsyncThunk(
    "settingHideOrder/saveSettingHideOrder",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await SettingHideOrderService.saveSettingHideOrder(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


const initialState = {
    loading: false,
    listBank: [],
    listSettingHideOrder: [],
    settingHideOrderDetail: {},
};

export const storeSettingHideOrderSlice = createSlice({
    name: "settingHideOrder",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setSettingHideOrder: (state, action) => {
            state.settingHideOrderList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSettingHideOrderApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllSettingHideOrderApi.fulfilled, (state, action) => {
                state.loading = false;
                state.listSettingHideOrder = action.payload;
            })
            .addCase(getAllSettingHideOrderApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(saveSettingHideOrderApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveSettingHideOrderApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(saveSettingHideOrderApi.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setSettingHideOrder } = storeSettingHideOrderSlice.actions;

export default storeSettingHideOrderSlice.reducer;
