import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import DriverFeedbackService from "services/DriverFeedbackService";
import Utils from "utils";
import openNotification from "utils/notification";

export const getDriverFeedbackGoodApi = createAsyncThunk(
    "driverFeedback/getDriverFeedbackGoodApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await DriverFeedbackService.getListDriverFeedbackGood(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getDriverFeedbackBadApi = createAsyncThunk(
    "driverFeedback/getDriverFeedbackBadApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await DriverFeedbackService.getListDriverFeedbackBad(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getDriverFeedbackById = createAsyncThunk(
    "driverFeedback/getDriverFeedbackById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await DriverFeedbackService.getDriverFeedbackById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const addDriverFeedbackApi = createAsyncThunk(
    "driverFeedback/addDriverFeedbackApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await DriverFeedbackService.createDriverFeedback(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateDriverFeedbackApi = createAsyncThunk(
    "driverFeedback/updateDriverFeedbackApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await DriverFeedbackService.updateDriverFeedback(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delDriverFeedbackApi = createAsyncThunk(
    "driverFeedback/delDriverFeedbackApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const response = await DriverFeedbackService.delDriverFeedback(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);



const initialState = {
    loading: false,
    driverFeedbackGoodList: [],
    driverFeedbackBadList: [],
    driverFeedbackDetail: {},
};
export const driverFeedbackSlice = createSlice({
    name: "driverFeedback",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setDriverFeedbackDetail: (state) => {
            state.loading = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDriverFeedbackGoodApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDriverFeedbackGoodApi.fulfilled, (state, action) => {
                state.loading = false;
                state.driverFeedbackGoodList = action.payload;
            })
            .addCase(getDriverFeedbackGoodApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getDriverFeedbackBadApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDriverFeedbackBadApi.fulfilled, (state, action) => {
                state.loading = false;
                state.driverFeedbackBadList = action.payload;
            })
            .addCase(getDriverFeedbackBadApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getDriverFeedbackById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDriverFeedbackById.fulfilled, (state, action) => {
                state.loading = false;
                state.driverFeedbackDetail = action.payload;
            })
            .addCase(getDriverFeedbackById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addDriverFeedbackApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(addDriverFeedbackApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addDriverFeedbackApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateDriverFeedbackApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateDriverFeedbackApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateDriverFeedbackApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delDriverFeedbackApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delDriverFeedbackApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delDriverFeedbackApi.rejected, (state, action) => {
                state.loading = false;
            })

    },
});

export const { showLoading, setDriverFeedbackDetail } = driverFeedbackSlice.actions;

export default driverFeedbackSlice.reducer;