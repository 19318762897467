import axiosInstance from "../auth/FetchInterceptor";
import { REPORT_GET_DASHBOARD_INDAY, REPORT_GET_IMPORTANT_DRIVER, REPORT_GET_IMPORTANT_DRIVER_CHART, REPORT_GET_IMPORTANT_INDEX, REPORT_GET_IMPORTANT_INDEX_CHART, REPORT_GET_IMPORTANT_USER, REPORT_GET_IMPORTANT_USER_CHART, REPORT_GET_IMPORTANT_VEHICLE, REPORT_GET_IMPORTANT_VOUCHER, REPORT_GET_IMPORTANT_VOUCHER_CHART, REPORT_GET_LIST_RATING_DRIVER, REPORT_GET_RATING_ORDER, REPORT_GET_RATING_ORDER_SATISFIED, REPORT_GET_RATING_ORDER_UNSATISFIED, REPORT_GET_REASON_USER_CANCEL, REPORT_GET_REVENUE_SHIFT_ORDER, REPORT_GET_REVENUE_VEHICLE, REPORT_GET_TOP_DRIVER, REPORT_REVENUE, REPORT_REVENUE_TYPE_ORDER, REPORT_REVENUE_VEHICLE_EXPORT_EXEL } from "constants/ApiConstant";

const ReportService = {}

ReportService.reportRevenue = function () {
    return axiosInstance.get(REPORT_REVENUE, null);
};
ReportService.getImportantIndex = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_INDEX, data);
};
ReportService.getImportantIndexChart = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_INDEX_CHART, data);
};
ReportService.getRevenueTypeOrder = function (data) {
    return axiosInstance.post(REPORT_REVENUE_TYPE_ORDER, data);
};
ReportService.getTopDriver = function (data) {
    return axiosInstance.post(REPORT_GET_TOP_DRIVER, data);
};
ReportService.getRevenueShiftOrder = function (data) {
    return axiosInstance.post(REPORT_GET_REVENUE_SHIFT_ORDER, data);
};
ReportService.getRatingOrder = function (data) {
    return axiosInstance.post(REPORT_GET_RATING_ORDER, data);
};
ReportService.getImportantIndexVoucher = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_VOUCHER, data);
};
ReportService.getImportantVouherChart = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_VOUCHER_CHART, data);
};
ReportService.getImportantIndexDriver = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_DRIVER, data);
};
ReportService.getImportantDriverChart = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_DRIVER_CHART, data);
};
ReportService.getListRatingDriver = function (data) {
    return axiosInstance.post(REPORT_GET_LIST_RATING_DRIVER, data);
};
ReportService.getRatingOrderSatisfied = function (data) {
    return axiosInstance.post(REPORT_GET_RATING_ORDER_SATISFIED, data);
};
ReportService.getRatingOrderUnSatisfied = function (data) {
    return axiosInstance.post(REPORT_GET_RATING_ORDER_UNSATISFIED, data);
};
ReportService.getImportantIndexVehicle = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_VEHICLE, data);
};
ReportService.getRevenueVehicle = function (data) {
    return axiosInstance.post(REPORT_GET_REVENUE_VEHICLE, data);
};
ReportService.getImportantIndexUser = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_USER, data);
};
ReportService.getImportantUserChart = function (data) {
    return axiosInstance.post(REPORT_GET_IMPORTANT_USER_CHART, data);
};
ReportService.getReasonUserCancel = function (data) {
    return axiosInstance.post(REPORT_GET_REASON_USER_CANCEL, data);
};
ReportService.getDashBoardInDay = function () {
    return axiosInstance.get(REPORT_GET_DASHBOARD_INDAY, null);
};
ReportService.revenueVehicleExportExelApi = function (data) {
  return axiosInstance.post(REPORT_REVENUE_VEHICLE_EXPORT_EXEL, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: "blob",
  });
};


export default ReportService;