import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import OrderService from "services/OrderService";
import GooglePlacesServices from "services/GooglePlacesServices";

export const orderSearchApi = createAsyncThunk(
  "order/orderSearchApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.orderSearchApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getDropdownVehicleDriver = createAsyncThunk(
  "order/getDropdownVehicleDriver",
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.getDropdownVehicleDriver(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const addManualBookingApi = createAsyncThunk(
  "order/addManualBookingApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await OrderService.addManualBookingApi(payload);
      console.log("Giá trị response:", response);
      console.log("Giá trị onSuccess:", onSuccess);

      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const updateManualBookingApi = createAsyncThunk(
  "order/updateManualBookingApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await OrderService.updateManualBookingApi(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const deleteManualBookingApi = createAsyncThunk(
  "order/deleteManualBookingApi",
  async (data, { rejectWithValue }) => {
    try {
      const { onSuccess } = data;
      const payload = cloneDeep(data);
      delete payload.onSuccess;
      const response = await OrderService.deleteManualBookingApi(payload);
      if (onSuccess) onSuccess(response);
      openNotification(
        "success",
        Utils.setLocale("admin.settings.popup.notification.success"),
        Utils.setLocale("admin.settings.popup.notification.title")
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const getAutoCompleteSearch = createAsyncThunk(
  "order/getAutoCompleteSearch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await GooglePlacesServices.getAutoCompleteSearch(data);
      // console.log("response",response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const GetOrderDetail = createAsyncThunk(
  "order/GetOrderDetail",
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.GetOrderDetail(data);
      // console.log("response",response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const orderHistoryApi = createAsyncThunk(
  "order/orderHistoryApi",
  async (data, { rejectWithValue }) => {
    try {
      const response = await OrderService.orderHistoryApi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const orderDetailForMapApi = createAsyncThunk(
  "order/orderDetailForMap",
  async (data, { rejectWithValue }) => {
    try {
      const responseHis = await OrderService.orderHistoryApi(data);

      const detailRes = await OrderService.GetOrderDetail(data);
      return {
        Detail: detailRes.data,
        History: responseHis.data
      };
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);
const initialState = {
  loading: false,
  orderList: [],
  dropdownVehicleDriver: [],
  orderHistoryList: [],
};
export const warningSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(orderSearchApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderSearchApi.fulfilled, (state, action) => {
        state.loading = false;
        state.orderList = action.payload;
      })
      .addCase(orderSearchApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getDropdownVehicleDriver.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDropdownVehicleDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.dropdownVehicleDriver = action.payload;
      })
      .addCase(getDropdownVehicleDriver.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(addManualBookingApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(addManualBookingApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addManualBookingApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateManualBookingApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateManualBookingApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateManualBookingApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteManualBookingApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteManualBookingApi.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteManualBookingApi.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(orderHistoryApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderHistoryApi.fulfilled, (state, action) => {
        state.loading = false;
        state.orderHistoryList = action.payload;
      })
      .addCase(orderHistoryApi.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, setFilterWarning } = warningSlice.actions;

export default warningSlice.reducer;
