import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import VehicleService from "services/VehicleService";
import Utils from "utils";
import openNotification from "utils/notification";

export const getVehicleApi = createAsyncThunk(
    "vehicle/getVehicleApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await VehicleService.getListVehicle(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getVehicleById = createAsyncThunk(
    "vehicle/getVehicleById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await VehicleService.getVehicleById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const addVehicleApi = createAsyncThunk(
    "vehicle/addVehicleApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await VehicleService.createVehicle(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateVehicleApi = createAsyncThunk(
    "vehicle/updateVehicleApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await VehicleService.updateVehicle(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delVehicleApi = createAsyncThunk(
    "vehicle/delVehicleApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const response = await VehicleService.delVehicle(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    vehicleList: [],
    vehicleDetail: {},
};
export const vehicleSlice = createSlice({
    name: "vehicle",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setVehicleDetail: (state) => {
            state.loading = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getVehicleApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getVehicleApi.fulfilled, (state, action) => {
                state.loading = false;
                state.vehicleList = action.payload;
            })
            .addCase(getVehicleApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getVehicleById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getVehicleById.fulfilled, (state, action) => {
                state.loading = false;
                state.vehicleDetail = action.payload;
            })
            .addCase(getVehicleById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addVehicleApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(addVehicleApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addVehicleApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateVehicleApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateVehicleApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateVehicleApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delVehicleApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delVehicleApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delVehicleApi.rejected, (state, action) => {
                state.loading = false;
            })
    },
});

export const { showLoading, setVehicleDetail } = vehicleSlice.actions;

export default vehicleSlice.reducer;
