import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import WorkingAreaSettingService from "services/WorkingAreaSettingService";


export const getWorkingAreaApi = createAsyncThunk(
    "workingArea/get-all",
    async (data, { rejectWithValue }) => {
        try {
            const response = await WorkingAreaSettingService.getAreas(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


export const createAreaApi = createAsyncThunk(
    "workingArea/create-area",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await WorkingAreaSettingService.createArea(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateAreaApi = createAsyncThunk(
    "workingArea/update-area",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await WorkingAreaSettingService.updateArea(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delAreaApi = createAsyncThunk(
    "workingArea/delete-area",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await WorkingAreaSettingService.delArea(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


const initialState = {
    loading: false,
    areaList: [],
};
export const storeWorkingAreaSlice = createSlice({
    name: "workingArea",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWorkingAreaApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getWorkingAreaApi.fulfilled, (state, action) => {
                state.loading = false;
                state.areaList = action.payload;
            })
            .addCase(getWorkingAreaApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(createAreaApi.pending, (state, action) => {
                state.loading = false;
            })
            .addCase(createAreaApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(createAreaApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateAreaApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateAreaApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateAreaApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delAreaApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delAreaApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delAreaApi.rejected, (state, action) => {
                state.loading = false;
            })
    },
});

export const { showLoading } = storeWorkingAreaSlice.actions;

export default storeWorkingAreaSlice.reducer;