import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import CollaboratorService from "services/CollaboratorService";

export const getCollaboratorApi = createAsyncThunk(
    "collaborator/getCollaborator",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CollaboratorService.getCollaborator(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getCollaboratorById = createAsyncThunk(
    "collaborator/getCollaboratorById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await CollaboratorService.getCollaboratorById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getAllBank = createAsyncThunk(
    "collaborator/getAllBank",
    async (_, { rejectWithValue }) => {
        try {
            const response = await CollaboratorService.getAllBank();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const createCollaboratorApi = createAsyncThunk(
    "collaborator/createCollaborator",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorService.createCollaborator(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const checkCollaboratorApi = createAsyncThunk(
    "collaborator/checkCollaborator",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorService.checkCollaborator(payload);
            openNotification(
                "success",
                response.ret?.description,
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateCollaboratorApi = createAsyncThunk(
    "collaborator/updateCollaborator",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorService.updateCollaborator(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updatePaymentCollaboratorApi = createAsyncThunk(
    "collaborator/updatePaymentCollaborator",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorService.updatePaymentCollaborator(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delCollaboratorApi = createAsyncThunk(
    "collaborator/delCollaboratorApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorService.delCollaborator(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


export const reportOrderCollaborator = createAsyncThunk(
    "collaborator/reportOrderCollaborator",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CollaboratorService.reportOrderCollaborator(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const reportCollaborator = createAsyncThunk(
    "collaborator/reportCollaborator",
    async (_, { rejectWithValue }) => {
        try {
            const response = await CollaboratorService.reportCollaborator();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getAllTopCollaborator = createAsyncThunk(
    "collaborator/getAllTopCollaborator",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CollaboratorService.topCollaborator(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    listBank: [],
    listCollaborator: [],
    dataTopCollaborator: [],
    dataReportCollaborator: [],
    dataReportOrderCollaborator: [],
    collaboratorDetail: {},
    collaboratorCheckDetail: {}
};

export const storeCollaboratorSlice = createSlice({
    name: "collaborator",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setCollaborator: (state, action) => {
            state.collaboratorList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCollaboratorApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCollaboratorApi.fulfilled, (state, action) => {
                state.loading = false;
                state.listCollaborator = action.payload;
            })
            .addCase(getCollaboratorApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(reportCollaborator.pending, (state) => {
                state.loading = true;
            })
            .addCase(reportCollaborator.fulfilled, (state, action) => {
                state.loading = false;
                state.dataReportCollaborator = action.payload;
            })
            .addCase(reportCollaborator.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllTopCollaborator.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllTopCollaborator.fulfilled, (state, action) => {
                state.loading = false;
                state.dataTopCollaborator = action.payload;
            })
            .addCase(getAllTopCollaborator.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(reportOrderCollaborator.pending, (state) => {
                state.loading = true;
            })
            .addCase(reportOrderCollaborator.fulfilled, (state, action) => {
                state.loading = false;
                state.dataReportOrderCollaborator = action.payload;
            })
            .addCase(reportOrderCollaborator.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllBank.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllBank.fulfilled, (state, action) => {
                state.loading = false;
                state.listBank = action.payload;
            })
            .addCase(getAllBank.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCollaboratorById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCollaboratorById.fulfilled, (state, action) => {
                state.loading = false;
                state.collaboratorDetail = action.payload;
            })
            .addCase(getCollaboratorById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(checkCollaboratorApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkCollaboratorApi.fulfilled, (state, action) => {
                state.loading = false;
                state.collaboratorCheckDetail = action.payload;
            })
            .addCase(checkCollaboratorApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(createCollaboratorApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(createCollaboratorApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(createCollaboratorApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCollaboratorApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCollaboratorApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCollaboratorApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updatePaymentCollaboratorApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePaymentCollaboratorApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updatePaymentCollaboratorApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delCollaboratorApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delCollaboratorApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delCollaboratorApi.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setCollaborator } = storeCollaboratorSlice.actions;

export default storeCollaboratorSlice.reducer;
