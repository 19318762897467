import fetch from "auth/FetchInterceptor";
// import { IDENTITY_ENDPOINT_URL } from 'configs/AppConfig';
import {
  ACCOUNT_LOGIN,
  CHANGE_PASS_PROFILE,
  FORGOT_PASSWORD,
  GET_PROFILE,
  RESET_PASSWORD,
  SEARCH_BY_ROLES,
  SIGN_OUT,
  UPDATE_PROFILE
} from "constants/ApiConstant";
const AuthService = {};

AuthService.getProfileApi = function (data) {
  return fetch.get(GET_PROFILE, data, {
    baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
  });
};

AuthService.login = function (data) {
  return fetch.post(ACCOUNT_LOGIN, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};

AuthService.signOutApi = function (data) {
  return fetch.post(SIGN_OUT, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};

AuthService.forgotPassword = function (data) {
  return fetch.post(FORGOT_PASSWORD, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};

AuthService.resetPassword = function (data) {
  return fetch.post(RESET_PASSWORD, data, {
    baseURL: process.env.REACT_APP_IDENTITY_ENDPOINT_URL,
  });
};

AuthService.searchByRoles = function (data) {
  return fetch.post(SEARCH_BY_ROLES, data, {
    baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
  });
};

AuthService.updateProfile = function (data) {
  return fetch.put(UPDATE_PROFILE, data, {
    baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
  });
};
AuthService.changePassProfile = function (data) {
  return fetch.post(CHANGE_PASS_PROFILE, data, {
    baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
  });
};

AuthService.register = function (data) {
  return fetch({
    url: "/User/register",
    method: "post",
    data: data,
  });
};

AuthService.logout = function (data) {
  return fetch({
    url: "/User/logout",
    method: "post",
    data,
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: "/auth/loginInOAuth",
    method: "post",
  });
};

export default AuthService;
