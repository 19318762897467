import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import otherService from "services/OtherServiceService";
import { cloneDeep } from "lodash";
import Utils from "utils";
import openNotification from "utils/notification";

export const getOtherServiceDetailApi = createAsyncThunk(
    "otherService/detailOtherService",
    async (data, { rejectWithValue }) => {
        try {
            const response = await otherService.getOtherServiceDetail(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getOtherServiceDetailFavorite = createAsyncThunk(
    "otherService/getOtherServiceDetailFavorite",
    async (data, { rejectWithValue }) => {
        try {
            const response = await otherService.getOtherServiceDetailFavorite(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getOtherServiceApi = createAsyncThunk(
    "otherService/getOtherServiceApi",
    async (data, { rejectWithValue }) => {
        try {
            const response = await otherService.getListOtherService(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getOtherServiceById = createAsyncThunk(
    "otherService/getOtherServiceById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await otherService.getOtherServiceById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const addOtherServiceApi = createAsyncThunk(
    "otherService/addOtherServiceApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await otherService.createOtherService(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateOtherServiceApi = createAsyncThunk(
    "otherService/updateOtherServiceApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await otherService.updateOtherService(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delOtherServiceApi = createAsyncThunk(
    "otherService/delOtherServiceApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const response = await otherService.delOtherService(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const favoriteOtherService = createAsyncThunk(
    "otherService/favoriteOtherService",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await otherService.FavoriteOtherService(payload);
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getOtherServiceViewCount = createAsyncThunk(
    "otherService/getOtherServiceViewCount",
    async (id, { rejectWithValue }) => {
        try {
            const response = await otherService.getOtherServiceViewCount(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getOtherServiceLikeCount = createAsyncThunk(
    "otherService/getOtherServiceLikeCount",
    async (id, { rejectWithValue }) => {
        try {
            const response = await otherService.getOtherServiceLikeCount(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getAllComment = createAsyncThunk(
    "otherService/getAllComment",
    async (id, { rejectWithValue }) => {
        try {
            const response = await otherService.getAllComment(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const changeActiveComment = createAsyncThunk(
    "otherService/changeActiveComment",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const response = await otherService.changeActiveComment(data);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            if (onSuccess) onSuccess(response);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    otherServiceList: [],
    listViewCount: [],
    listLikeCount: [],
    listComment: [],
    otherServiceDetail: {},
    otherServiceDetailFavorite: {},
};
export const otherServiceSlice = createSlice({
    name: "OtherService",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setNewsDetail: (state) => {
            state.loading = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOtherServiceViewCount.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOtherServiceViewCount.fulfilled, (state, action) => {
                state.loading = false;
                state.listViewCount = action.payload;
            })
            .addCase(getOtherServiceViewCount.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getOtherServiceLikeCount.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOtherServiceLikeCount.fulfilled, (state, action) => {
                state.loading = false;
                state.listLikeCount = action.payload;
            })
            .addCase(getOtherServiceLikeCount.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getOtherServiceApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOtherServiceApi.fulfilled, (state, action) => {
                state.loading = false;
                state.otherServiceList = action.payload;
            })
            .addCase(getOtherServiceApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getOtherServiceById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOtherServiceById.fulfilled, (state, action) => {
                state.loading = false;
                state.otherServiceDetail = action.payload;
            })
            .addCase(getOtherServiceById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addOtherServiceApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(addOtherServiceApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addOtherServiceApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateOtherServiceApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOtherServiceApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateOtherServiceApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delOtherServiceApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delOtherServiceApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delOtherServiceApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getOtherServiceDetailApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOtherServiceDetailApi.fulfilled, (state, action) => {
                state.loading = false;
                state.otherServiceDetail = action.payload;
            })
            .addCase(getOtherServiceDetailApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getOtherServiceDetailFavorite.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOtherServiceDetailFavorite.fulfilled, (state, action) => {
                state.loading = false;
                state.otherServiceDetailFavorite = action.payload;
            })
            .addCase(getOtherServiceDetailFavorite.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(favoriteOtherService.pending, (state) => {
                state.loading = true;
            })
            .addCase(favoriteOtherService.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(favoriteOtherService.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getAllComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllComment.fulfilled, (state, action) => {
                state.loading = false;
                state.listComment = action.payload;
            })
            .addCase(getAllComment.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(changeActiveComment.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeActiveComment.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(changeActiveComment.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setNewsDetail } = otherServiceSlice.actions;

export default otherServiceSlice.reducer;