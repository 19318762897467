import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LanguageService from "services/LanguageService";


export const getLanguageApi = createAsyncThunk(
    "language/getAllLanguage",
    async ({ rejectWithValue }) => {
        try {
            const response = await LanguageService.getListLanguage();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    listLanguage: [],
};

export const LanguageSystem = createSlice({
    name: "language",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setLanguage: (state, { payload }) => {
            state.listLanguage = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLanguageApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getLanguageApi.fulfilled, (state, action) => {
                state.loading = false;
                state.listLanguage = action.payload;
            })
            .addCase(getLanguageApi.rejected, (state, action) => {
                state.loading = false;
            })
    },
});

export const { showLoading, setLanguage } = LanguageSystem.actions;
export default LanguageSystem.reducer;