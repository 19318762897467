import axiosInstance from "auth/FetchInterceptor";
import { WORKING_AREA_API } from "constants/ApiConstant";
const WorkingAreaSettingService = {};

WorkingAreaSettingService.getAreas = function (data) {
    return axiosInstance.post(`${WORKING_AREA_API}/search-grid`, data);
};

WorkingAreaSettingService.createArea = function (data) {
    return axiosInstance.post(WORKING_AREA_API, data);
};

WorkingAreaSettingService.updateArea = function (data) {
    return axiosInstance.put(WORKING_AREA_API, data);
};
WorkingAreaSettingService.delArea = function (id) {
    return axiosInstance.delete(`${WORKING_AREA_API}/${id}`, null);
};

export default WorkingAreaSettingService;