export const API_BASE_URL = "https://your-api-url.com/";

const PREFIX = "/en/api/v1.0";
export const GET_PROFILE = `${PREFIX}/User/profile`;
export const ACCOUNT_LOGIN = `${PREFIX}/User/login`;
export const SIGN_OUT = `${PREFIX}/User/logout`;
export const FORGOT_PASSWORD = `${PREFIX}/User/password/forgot-by-email`;
export const RESET_PASSWORD = `${PREFIX}/User/password/reset-by-email`;
export const SEARCH_BY_ROLES = `${PREFIX}/User/search-by-roles`;
export const REFRESH_TOKEN_API = `${PREFIX}/TokenManager/refresh-token`;
export const UPDATE_PROFILE = `${PREFIX}/User/profile`;
export const CHANGE_PASS_PROFILE = `${PREFIX}/User/change-pass-profile`;

const SETTING_API = `${PREFIX}/Setting`;
export const SETTING_STORE_CATEGORY_API = `${SETTING_API}/store-categories`;
export const SETTING_MENU_CATEGORY_API = `${SETTING_API}/menu-categories`;
export const SETTING_STORE_UTILITY_API = `${SETTING_API}/store-utility`;
export const SETTING_PRODUCT_ATTRIBUTE = `${SETTING_API}/product-attribute-categories`;
export const SETTING_BANNER = `${SETTING_API}/banner`;
export const SETTING_TERM_CONDITION = `${SETTING_API}/system-setting/term-condition`;
export const SETTING_PRIVACY = `${SETTING_API}/system-setting/privacy`;

const LOCATION_API = `${PREFIX}/Location`;
export const PROVINCE_BY_COUNTRY = `${LOCATION_API}/province-by-country`;
export const DISTRICT_BY_PROVINCE = `${LOCATION_API}/district-by-province`;
export const WARD_BY_DISTRICT = `${LOCATION_API}/ward-by-district`;
export const PRODUCT_API = `${PREFIX}/Product`;
export const PRODUCT_SEARCH_GRID = `${PRODUCT_API}/search-grid`;
export const PRODUCT_CHANGE_STATE = `${PRODUCT_API}/change-state`;
export const PRODUCTS_COPY = `${PRODUCT_API}/copy-product`;
export const PRODUCTS_GROUP_CATEGORY = `${PRODUCT_API}/group-category`;
export const PRODUCTS_CHANGE_STATUS = `${PRODUCT_API}/change-status`;
export const PRODUCTS_DISCOUNT = `${PRODUCT_API}/products-discount`;

const NOTIFICATION_API = `${PREFIX}/Notification`;
export const SEARCH_NOTIFICATION = `${NOTIFICATION_API}/search`;
export const READ_ALL_NOTIFICATION = `${NOTIFICATION_API}/read-all`;
export const DEL_ALL_NOTIFICATION = `${NOTIFICATION_API}/delete-all`;
export const SAVE_FIREBASE_TOKEN_NOTIFICATION = `${NOTIFICATION_API}/save-firebase-token`;
export const READ_NOTIFICATION = `${NOTIFICATION_API}/mark-read`;
export const SAVE_LABEL_STAR_NOTIFICATION = `${NOTIFICATION_API}/save-label-star`;
export const DEL_MULTI_NOTIFICATION = `${NOTIFICATION_API}/delete-multi`;

export const VOUCHER_API = `${PREFIX}/Voucher`;
export const VOUCHER_SEARCH_GRID = `${VOUCHER_API}/search-grid`;
export const VOUCHER_DETAIL_API = `${VOUCHER_API}/detail`;
export const VOUCHER_TICKET_SEARCH_GRID = `${VOUCHER_API}/search-grid-ticket`;
export const VOUCHER_TICKET_EXPORT_EXEL = `${VOUCHER_API}/export-exel`;
export const VOUCHER_TICKET_EXPORT_EXEL_HARD_COPY = `${VOUCHER_API}/export-exel-hard-copy`;

const SETTING_SHIFT_API = `${PREFIX}/SettingShift`;
export const LIST_SHIFT_MONTHS_YEAR_API = `${SETTING_SHIFT_API}/list-shift-months-year`;
export const LIST_SHIFT_SEARCH_GRID_API = `${SETTING_SHIFT_API}/list-shift-search-grid`;
export const LIST_DROPDOWN_DRIVER = `${SETTING_SHIFT_API}/list-dropdown-driver`;
export const UPDATE_LIST_SHIFT_DRIVER = `${SETTING_SHIFT_API}/update-shift-driver`;
export const DRIVER_SHIFT_LIST_SEARCH_GRID = `${SETTING_SHIFT_API}/driver-shift-list-search-grid`;
export const COPY_SHIFT_DAY_API = `${SETTING_SHIFT_API}/copy-shift-day`;
export const COPY_SHIFT_MONTH_API = `${SETTING_SHIFT_API}/copy-shift-month`;
export const GET_ALL_SHIFT_API = `${SETTING_SHIFT_API}/all-shifts`;
export const UPDATE_SHIFT_API = `${SETTING_SHIFT_API}/update-shift`;

export const WARNING_API = `${PREFIX}/Warning`;
export const WARNING_SEARCH_GRID = `${WARNING_API}/search-grid`;
export const HANDLE_WARNING = `${WARNING_API}/warning-handle`;

export const SEARCH_WARNING_DETAIL = `${WARNING_API}/detail`;
export const MAP_SEARCH_API = `${PREFIX}/Monitor/search`;
export const MAP_WORKINGAREA_API = `${PREFIX}/Monitor/working-areas`;
export const SEARCH_PLACE_API = `${PREFIX}/Monitor/search-place`;
export const GET_PLACE_DETAIL_API = `${PREFIX}/Monitor/place-detail`;
export const GET_DISTANCE_MATRIX = `${PREFIX}/Monitor/get-distance-matrix`;

//Aloha API
const ORDER_API = `${PREFIX}/Order`;
export const SEARCH_ORDER = `${ORDER_API}/search-grid`;
export const EXPORT_EXCEL_ORDER = `${ORDER_API}/export-exel`;
export const DROPDOWN_VEHICLE_DRIVER = `${ORDER_API}/dropdown-vehicle-driver`;
export const ADD_MANUAL_BOOKING = `${ORDER_API}/booking-manual`;
export const UPDATE_MANUAL_BOOKING = `${ORDER_API}/update-booking-manual`;
export const DELETE_MANUAL_BOOKING = `${ORDER_API}/delete-booking-manual`;
export const GET_ORDER_DETAIL = `${ORDER_API}/order-detail`;
export const GET_ORDER_HISTORY = `${ORDER_API}/order-history`;

export const CUSTOMER_API = `${PREFIX}/User`;
export const CUSTOMER_CHANGE_STATUS = `${CUSTOMER_API}/change-status`;
export const SEARCH_CUSTOMER = `${CUSTOMER_API}/search-grid-customer`;
export const SEARCH_ORDER_CUSTOMER = `${ORDER_API}/search-order-customer`;

export const EMPLOYEE_API = `${PREFIX}/User`;
export const EMPLOYEE_CREATE = `${EMPLOYEE_API}/create-user`;
export const EMPLOYEE_UPDATE = `${EMPLOYEE_API}/update-user`;
export const EMPLOYEE_CHANGE_STATUS = `${EMPLOYEE_API}/change-status`;
export const EMPLOYEE_CHANGE_PASSWORD = `${EMPLOYEE_API}/change-pass-employee`;
export const SEARCH_EMPLOYEE = `${EMPLOYEE_API}/search-grid-employee`;

export const DRIVER_API = `${PREFIX}/Driver`;
export const DRIVER_CREATE = `${DRIVER_API}/create-driver`;
export const DRIVER_UPDATE = `${DRIVER_API}/update-driver`;
export const DRIVER_CHANGE_STATUS = `${DRIVER_API}/change-driver`;
export const SEARCH_DRIVER = `${DRIVER_API}/search-grid`;

export const VEHICLE_API = `${PREFIX}/Vehicle`;
export const VEHICLE_CREATE = `${VEHICLE_API}/create-vehicle`;
export const VEHICLE_UPDATE = `${VEHICLE_API}/update-vehicle`;
export const SEARCH_VEHICLE = `${VEHICLE_API}/search-grid`;

export const PRICE_SETTING_API = `${PREFIX}/PriceSetting`;
export const PRICE_SETTING_CREATE = `${PRICE_SETTING_API}/create-price`;
export const PRICE_SETTING_UPDATE = `${PRICE_SETTING_API}/update-price`;
export const SEARCH_PRICE_SETTING = `${PRICE_SETTING_API}/search-grid`;
export const PRICE_SETTING_CHANGE_STATUS = `${PRICE_SETTING_API}/change-status`;

export const REVIEW_API = `${PREFIX}/ReviewDriver`;
export const SEARCH_REVIEW = `${REVIEW_API}/search-grid`;
export const REVIEW_CREATE = `${REVIEW_API}/create-review`;

export const PLACE_API = `${PREFIX}/Place`;
export const SEARCH_PLACE = `${PLACE_API}/search-grid`;
export const PLACE_DETAIL_API = `${PLACE_API}/detail`;
export const PLACE_CHANGE_STATUS = `${PLACE_API}/change-status`;


export const CITY_TOUR_API = `${PREFIX}/CityTour`;
export const SEARCH_CITY_TOUR = `${CITY_TOUR_API}/search-grid`;
export const DROPDOWN_PLACE = `${CITY_TOUR_API}/dropdown-place`;

export const DRIVER_FEEDBACK_API = `${PREFIX}/SettingDriverFeedback`;
export const DRIVER_FEEDBACK_GOOD = `${DRIVER_FEEDBACK_API}/get-all-good`;
export const DRIVER_FEEDBACK_BAD = `${DRIVER_FEEDBACK_API}/get-all-bad`;

const SYSTEM_SETTING_API = `${PREFIX}/SystemSetting`;
export const SAVE_SYSTEM_SETTING_API = `${SYSTEM_SETTING_API}/save-setting-system`;
export const SAVE_NOTIFY_TIME_API = `${SYSTEM_SETTING_API}/save-notify-time`;
export const GET_SYSTEM_SETTING_OTHER_API = `${SYSTEM_SETTING_API}/get-system-setting`;
export const GET_CANCEL_ORDER_REASON = `${SYSTEM_SETTING_API}/cancel-order-reason`;
export const GET_REJECT_ORDER_REASON = `${SYSTEM_SETTING_API}/reject-order-reason`;
export const GET_OFFLINE_REASON = `${SYSTEM_SETTING_API}/offline-reason`;
export const REASON_CREATE = `${SYSTEM_SETTING_API}/add-reason`;
export const REASON_UPDATE = `${SYSTEM_SETTING_API}/update-reason`;
export const REASON_DELETE = `${SYSTEM_SETTING_API}/delete-reason`;

const REPORT_API = `${PREFIX}/Report`;
export const REPORT_REVENUE = `${REPORT_API}/get-report-revenue`;
export const REPORT_GET_IMPORTANT_INDEX = `${REPORT_API}/get-important-index`;
export const REPORT_GET_IMPORTANT_INDEX_CHART = `${REPORT_API}/get-important-index-chart`;
export const REPORT_REVENUE_TYPE_ORDER = `${REPORT_API}/get-revenue-type-order`;
export const REPORT_GET_TOP_DRIVER = `${REPORT_API}/get-top-driver`;
export const REPORT_GET_REVENUE_SHIFT_ORDER = `${REPORT_API}/get-revenue-shift-order`;
export const REPORT_GET_RATING_ORDER = `${REPORT_API}/get-rating-order`;
export const REPORT_GET_IMPORTANT_VOUCHER = `${REPORT_API}/get-important-voucher`;
export const REPORT_GET_IMPORTANT_VOUCHER_CHART = `${REPORT_API}/get-important-voucher-chart`;
export const REPORT_GET_IMPORTANT_DRIVER = `${REPORT_API}/get-important-driver`;
export const REPORT_GET_IMPORTANT_DRIVER_CHART = `${REPORT_API}/get-important-driver-chart`;
export const REPORT_GET_LIST_RATING_DRIVER = `${REPORT_API}/get-list-rating-driver`;
export const REPORT_GET_RATING_ORDER_SATISFIED = `${REPORT_API}/get-rating-order-satisfied`;
export const REPORT_GET_RATING_ORDER_UNSATISFIED = `${REPORT_API}/get-rating-order-unsatisfied`;
export const REPORT_GET_IMPORTANT_VEHICLE = `${REPORT_API}/get-important-vehicle`;
export const REPORT_GET_REVENUE_VEHICLE = `${REPORT_API}/get-revenue-vehicle`;
export const REPORT_GET_IMPORTANT_USER = `${REPORT_API}/get-important-user`;
export const REPORT_GET_IMPORTANT_USER_CHART = `${REPORT_API}/get-important-user-chart`;
export const REPORT_GET_REASON_USER_CANCEL = `${REPORT_API}/get-reason-user-cancel`;
export const REPORT_GET_DASHBOARD_INDAY = `${REPORT_API}/get-dashboard-inday`;
export const REPORT_REVENUE_VEHICLE_EXPORT_EXEL = `${REPORT_API}/export-exel`;

export const WORKING_AREA_API = `${PREFIX}/WorkingAreaSetting`;

export const NEWS_API = `${PREFIX}/News`;
export const NEWS_CREATE = `${NEWS_API}/create-news`;
export const NEWS_UPDATE = `${NEWS_API}/update-news`;
export const SEARCH_NEWS = `${NEWS_API}/search-grid`;
export const NEWS_VIEW_COUNT = `${NEWS_API}/view-count`;
export const NEWS_DETAIL_API = `${NEWS_API}/detail`;

export const OTHER_SERVICE_API = `${PREFIX}/OtherService`;
export const OTHER_SERVICE_CREATE = `${OTHER_SERVICE_API}/create-otherService`;
export const OTHER_SERVICE_UPDATE = `${OTHER_SERVICE_API}/update-otherService`;
export const SEARCH_OTHER_SERVICE = `${OTHER_SERVICE_API}/search-grid`;
export const OTHER_SERVICE_VIEW_COUNT = `${OTHER_SERVICE_API}/view-count`;
export const OTHER_SERVICE_LIKE_COUNT = `${OTHER_SERVICE_API}/like-count`;
export const OTHER_SERVICE_DETAIL = `${OTHER_SERVICE_API}/detail`;
export const OTHER_SERVICE_DETAIL_FAVORITE = `${OTHER_SERVICE_API}/detail-favorite`;
export const OTHER_SERVICE_FAVORITE = `${OTHER_SERVICE_API}/favorite-service`;
export const ALL_COMMENT = `${OTHER_SERVICE_API}/get-all-comment`;
export const LIKE_COMMENT = `${OTHER_SERVICE_API}/like-comment`;
export const CHANGE_ACTIVE_COMMENT = `${OTHER_SERVICE_API}/change-active-comment`;

export const LANGUAGE_API = `${PREFIX}/Language`;
export const LANGUAGE_GET_ALL = `${LANGUAGE_API}/get-all`;

export const ORDER_REASON_API = `${PREFIX}/CancelOrderReason`;
export const GET_ALL_REASON = `${ORDER_REASON_API}/get-all`;

export const COLLABORATOR_API = `${PREFIX}/Collaborator`;
export const COLLABORATOR_CREATE = `${COLLABORATOR_API}/create`;
export const COLLABORATOR_UPDATE = `${COLLABORATOR_API}/update`;
export const SEARCH_COLLABORATOR = `${COLLABORATOR_API}/search`;
export const COLLABORATOR_ALL_BANK = `${COLLABORATOR_API}/get-all-bank`;
export const COLLABORATOR_UPDATE_PAYMENT = `${COLLABORATOR_API}/update-payment`;
export const COLLABORATOR_DETAIL_API = `${COLLABORATOR_API}/detail`;
export const ORDER_COLLABORATOR = `${COLLABORATOR_API}/report-collaborator-order`;
export const TOP_COLLABORATOR = `${COLLABORATOR_API}/top-collaborator`;
export const REMOVE_ROLE_COLLABORATOR = `${COLLABORATOR_API}/remove-role`;
export const REPORT_COLLABORATOR = `${COLLABORATOR_API}/report-collaborator`;
export const CHECK_COLLABORATOR = `${COLLABORATOR_API}/check`;

export const COLLABORATOR_PAYMENT_API = `${PREFIX}/CollaboratorPayment`;
export const SEARCH_COLLABORATOR_PAYMENT = `${COLLABORATOR_PAYMENT_API}/search`;
export const COLLABORATOR_PAYMENT_CHANGE_STATUS = `${COLLABORATOR_PAYMENT_API}/change-status`;
export const COLLABORATOR_PAYMENT_UPDATE_MANY_STATUS = `${COLLABORATOR_PAYMENT_API}/update-many-status`;
export const COLLABORATOR_PAYMENT_DETAIL_API = `${COLLABORATOR_PAYMENT_API}/detail`;



export const COLLABORATOR_SETTING_API = `${PREFIX}/CollaboratorSetting`;
export const COLLABORATOR_SETTING_CREATE = `${COLLABORATOR_SETTING_API}/create`;
export const COLLABORATOR_SETTING_UPDATE = `${COLLABORATOR_SETTING_API}/update`;
export const GET_ALL_COLLABORATOR_SETTING = `${COLLABORATOR_SETTING_API}/get-all`;
export const COLLABORATOR_SETTING_SAVE = `${COLLABORATOR_SETTING_API}/save`;

export const SETTING_HIDE_ORDER_API = `${PREFIX}/SettingHideOrder`;
export const GET_ALL_SETTING_HIDE_ORDER = `${SETTING_HIDE_ORDER_API}/get-all`;
export const SETTING_HIDE_ORDER_SAVE = `${SETTING_HIDE_ORDER_API}/save`;