import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import DriverService from "services/DriverService";

export const getDriverApi = createAsyncThunk(
    "driver/getDriver",
    async (data, { rejectWithValue }) => {
        try {
            const response = await DriverService.getDriver(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getDriverById = createAsyncThunk(
    "driver/getDriverById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await DriverService.getDriverById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const createDriverApi = createAsyncThunk(
    "driver/createDriver",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await DriverService.createDriver(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateDriverApi = createAsyncThunk(
    "driver/updateDriver",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await DriverService.updateDriver(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delDriverApi = createAsyncThunk(
    "driver/delDriverApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await DriverService.delDriver(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const changeStatusDriver = createAsyncThunk(
    "driver/changeStatusDriver",
    async (data, { rejectWithValue }) => {
        try {
            const response = await DriverService.changeStatusDriver(data);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    driverList: [],
    driverDetail: {},
};
export const storeDriverSlice = createSlice({
    name: "driver",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setDriver: (state, action) => {
            state.driverList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDriverApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDriverApi.fulfilled, (state, action) => {
                state.loading = false;
                state.driverList = action.payload;
            })
            .addCase(getDriverApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getDriverById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDriverById.fulfilled, (state, action) => {
                state.loading = false;
                state.driverDetail = action.payload;
            })
            .addCase(getDriverById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(createDriverApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(createDriverApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(createDriverApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateDriverApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateDriverApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateDriverApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delDriverApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delDriverApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delDriverApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(changeStatusDriver.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeStatusDriver.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(changeStatusDriver.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setDriver } = storeDriverSlice.actions;

export default storeDriverSlice.reducer;
