import axiosInstance from "auth/FetchInterceptor";
import { ORDER_REASON_API, GET_ALL_REASON } from "constants/ApiConstant";
const OrderReason = {};

OrderReason.getListOrderReason = function () {
    return axiosInstance.get(GET_ALL_REASON, null);
};
OrderReason.createOrderReason = function (data) {
    return axiosInstance.post(ORDER_REASON_API, data);
};
OrderReason.updateOrderReason = function (data) {
    return axiosInstance.put(ORDER_REASON_API, data);
};
OrderReason.delOrderReason = function (id) {
    return axiosInstance.delete(`${ORDER_REASON_API}/${id}`, null);
};

export default OrderReason;