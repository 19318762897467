import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import PriceSettingService from "services/PriceSettingService";


export const getPriceSettingApi = createAsyncThunk(
    "priceSetting/getPriceSetting",
    async (data, { rejectWithValue }) => {
        try {
            const response = await PriceSettingService.getPriceSetting(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getPriceSettingById = createAsyncThunk(
    "priceSetting/getPriceSettingById",
    async (id, { rejectWithValue }) => {
        try {
            const response = await PriceSettingService.getPriceSettingById(id);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const createPriceSettingApi = createAsyncThunk(
    "priceSetting/createPriceSetting",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await PriceSettingService.createPriceSetting(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updatePriceSettingApi = createAsyncThunk(
    "priceSetting/updatePriceSetting",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await PriceSettingService.updatePriceSetting(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delPriceSettingApi = createAsyncThunk(
    "priceSetting/delPriceSettingApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await PriceSettingService.delPriceSetting(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const changeStatusPriceSetting = createAsyncThunk(
    "priceSetting/changeStatusPriceSetting",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const response = await PriceSettingService.changeStatusPriceSetting(data);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


const initialState = {
    loading: false,
    priceSettingList: [],
    priceSettingDetail: {},
};
export const storePriceSettingSlice = createSlice({
    name: "priceSetting",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setPriceSetting: (state, action) => {
            state.priceSettingList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPriceSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPriceSettingApi.fulfilled, (state, action) => {
                state.loading = false;
                state.priceSettingList = action.payload;
            })
            .addCase(getPriceSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getPriceSettingById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPriceSettingById.fulfilled, (state, action) => {
                state.loading = false;
                state.priceSettingDetail = action.payload;
            })
            .addCase(getPriceSettingById.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(createPriceSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(createPriceSettingApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(createPriceSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updatePriceSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePriceSettingApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updatePriceSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delPriceSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delPriceSettingApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delPriceSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(changeStatusPriceSetting.pending, (state) => {
                state.loading = true;
            })
            .addCase(changeStatusPriceSetting.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(changeStatusPriceSetting.rejected, (state, action) => {
                state.loading = false;
            })
    },
});

export const { showLoading, setPriceSetting } = storePriceSettingSlice.actions;

export default storePriceSettingSlice.reducer;