import axiosInstance from "auth/FetchInterceptor";
import { CUSTOMER_API, CUSTOMER_CHANGE_STATUS, SEARCH_CUSTOMER } from "constants/ApiConstant";
const CustomerService = {};


CustomerService.getCustomer = function (data) {
  return axiosInstance.post(SEARCH_CUSTOMER, data);
};
CustomerService.getCustomerById = function (id) {
  return axiosInstance.get(`${CUSTOMER_API}/${id}`, null);
};

CustomerService.changeStatusCustomer = function (data) {
  return axiosInstance.put(CUSTOMER_CHANGE_STATUS, data);
};


export default CustomerService;
