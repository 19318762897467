import reportService from "services/reportService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";


export const reportRevenue = createAsyncThunk(
    "report/reportRevenue",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.reportRevenue();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const getImportantIndex = createAsyncThunk(
    "report/getImportantIndex",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantIndex(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const getImportantIndexChart = createAsyncThunk(
    "report/getImportantIndexChart",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantIndexChart(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const getRevenueTypeOrder = createAsyncThunk(
    "report/getRevenueTypeOrder",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getRevenueTypeOrder(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const getTopDriver = createAsyncThunk(
    "report/getTopDriver",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getTopDriver(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const getRevenueShiftOrder = createAsyncThunk(
    "report/getRevenueShiftOrder",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getRevenueShiftOrder(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const getRatingOrder = createAsyncThunk(
    "report/getRatingOrder",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getRatingOrder(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);
export const getImportantIndexVoucher = createAsyncThunk(
    "report/getImportantIndexVoucher",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantIndexVoucher(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getImportantVouherChart = createAsyncThunk(
    "report/getImportantVouherChart",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantVouherChart(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getImportantIndexDriver = createAsyncThunk(
    "report/getImportantIndexDriver",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantIndexDriver(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getImportantDriverChart = createAsyncThunk(
    "report/getImportantDriverChart",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantDriverChart(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getListRatingDriver = createAsyncThunk(
    "report/getListRatingDriver",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getListRatingDriver(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


export const getRatingOrderSatisfied = createAsyncThunk(
    "report/getRatingOrderSatisfied",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getRatingOrderSatisfied(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);


export const getRatingOrderUnSatisfied = createAsyncThunk(
    "report/getRatingOrderUnSatisfied",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getRatingOrderUnSatisfied(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getImportantIndexVehicle = createAsyncThunk(
    "report/getImportantIndexVehicle",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantIndexVehicle(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getRevenueVehicle = createAsyncThunk(
    "report/getRevenueVehicle",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getRevenueVehicle(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getImportantIndexUser = createAsyncThunk(
    "report/getImportantIndexUser",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantIndexUser(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getImportantUserChart = createAsyncThunk(
    "report/getImportantUserChart",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getImportantUserChart(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getReasonUserCancel = createAsyncThunk(
    "report/getReasonUserCancel",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getReasonUserCancel(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const getDashBoardInDay = createAsyncThunk(
    "report/getDashBoardInDay",
    async (data, { rejectWithValue }) => {
        try {
            const response = await reportService.getDashBoardInDay();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const revenueVehicleExportExelApi = createAsyncThunk(
    "report/revenueVehicleExportExelApi",
    async (data, { rejectWithValue }) => {
      try {
        const { onSuccess } = data;
        const payload = cloneDeep(data);
        delete payload.onSuccess;
        const response = await reportService.revenueVehicleExportExelApi(data);
        if (onSuccess) onSuccess(response);
        return response;
      } catch (err) {
        return rejectWithValue(err.message || "Error");
      }
    }
  );


const initialState = {
    loading: false,
    reportRevenueList: [],
    reportImportantIndexList: [],
    reportImportantIndexChartList: [],
    reportRevenueTypeOrderList: [],
    reportTopDriverList: [],
    reportRevenueShiftOrderList: [],
    reportRatingOrderList: [],
    reportImportantVoucherList: [],
    reportImportantVoucherChartList: [],
    reportImportantDriverList: [],
    reportImportantDriverChartList: [],
    reportListRatingDriver:[],
    reportRatingOrderSatisfiedList: [],
    reportRatingOrderUnSatisfiedList: [],
    reportImportantVehicleList: [],
    reportRevenueVehicleList: [],
    reportImportantUserList: [],
    reportImportantUserChartList: [],
    reportReasonUserCancelList: [],
    dashBoardInDayList: [],
};

const reportSlice = createSlice(
    {
        name: "report",
        initialState,
        reducers: {
            showLoading: (state) => {
                state.loading = true;
            },
        },
        extraReducers: (builder) => {
            builder
                .addCase(reportRevenue.pending, (state) => {
                    state.loading = true;
                })
                .addCase(reportRevenue.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRevenueList = action.payload;
                })
                .addCase(reportRevenue.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantIndex.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantIndex.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantIndexList = action.payload;
                })
                .addCase(getImportantIndex.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantIndexChart.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantIndexChart.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantIndexChartList = action.payload;
                })
                .addCase(getImportantIndexChart.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getRevenueTypeOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getRevenueTypeOrder.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRevenueTypeOrderList = action.payload;
                })
                .addCase(getRevenueTypeOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getTopDriver.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getTopDriver.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportTopDriverList = action.payload;
                })
                .addCase(getTopDriver.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getRevenueShiftOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getRevenueShiftOrder.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRevenueShiftOrderList = action.payload;
                })
                .addCase(getRevenueShiftOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getRatingOrder.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getRatingOrder.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRatingOrderList = action.payload;
                })
                .addCase(getRatingOrder.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantIndexVoucher.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantIndexVoucher.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantVoucherList = action.payload;
                })
                .addCase(getImportantIndexVoucher.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantVouherChart.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantVouherChart.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantVoucherChartList = action.payload;
                })
                .addCase(getImportantVouherChart.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantIndexDriver.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantIndexDriver.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantDriverList = action.payload;
                })
                .addCase(getImportantIndexDriver.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantDriverChart.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantDriverChart.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantDriverChartList = action.payload;
                })
                .addCase(getImportantDriverChart.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getListRatingDriver.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getListRatingDriver.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportListRatingDriver = action.payload;
                })
                .addCase(getListRatingDriver.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getRatingOrderSatisfied.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getRatingOrderSatisfied.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRatingOrderSatisfiedList = action.payload;
                })
                .addCase(getRatingOrderSatisfied.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getRatingOrderUnSatisfied.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getRatingOrderUnSatisfied.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRatingOrderUnSatisfiedList = action.payload;
                })
                .addCase(getRatingOrderUnSatisfied.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantIndexVehicle.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantIndexVehicle.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantVehicleList = action.payload;
                })
                .addCase(getImportantIndexVehicle.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getRevenueVehicle.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getRevenueVehicle.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportRevenueVehicleList = action.payload;
                })
                .addCase(getRevenueVehicle.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantIndexUser.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantIndexUser.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantUserList = action.payload;
                })
                .addCase(getImportantIndexUser.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getImportantUserChart.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getImportantUserChart.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportImportantUserChartList = action.payload;
                })
                .addCase(getImportantUserChart.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getReasonUserCancel.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getReasonUserCancel.fulfilled, (state, action) => {
                    state.loading = false;
                    state.reportReasonUserCancelList = action.payload;
                })
                .addCase(getReasonUserCancel.rejected, (state, action) => {
                    state.loading = false;
                })
                .addCase(getDashBoardInDay.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getDashBoardInDay.fulfilled, (state, action) => {
                    state.loading = false;
                    state.dashBoardInDayList = action.payload;
                })
                .addCase(getDashBoardInDay.rejected, (state, action) => {
                    state.loading = false;
                });
        },
    }
);

export const { showLoading } = reportSlice.actions;

export default reportSlice.reducer;