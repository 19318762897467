import axiosInstance from "../auth/FetchInterceptor";
import { EXPORT_EXCEL_ORDER, SEARCH_ORDER_CUSTOMER } from "constants/ApiConstant";

const OrderService = {}

OrderService.getOrderCustomer = function (data, customerId) {
    return axiosInstance.post(`${SEARCH_ORDER_CUSTOMER}/${customerId}`, data);
};

OrderService.OrderExportExcel = function (data) {
    return axiosInstance.post(EXPORT_EXCEL_ORDER, data, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: "blob",
    });
};

export default OrderService;