import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";
import OrderReasonService from "services/OrderReasonService";
import Utils from "utils";
import openNotification from "utils/notification";

export const getOrderReasonApi = createAsyncThunk(
    "orderReason/getAllOrderReason",
    async ({ rejectWithValue }) => {
        try {
            const response = await OrderReasonService.getListOrderReason();
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const addOrderReason = createAsyncThunk(
    "orderReason/addOrderReason",
    async (data, { rejectWithValue }) => {
        const { onSuccess } = data;
        try {
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderReasonService.createOrderReason(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateOrderReason = createAsyncThunk(
    "orderReason/updateOrderReason",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderReasonService.updateOrderReason(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const deleteOrderReason = createAsyncThunk(
    "orderReason/delOrderReason",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await OrderReasonService.delOrderReason(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    listOrderReason: [],
};

export const OrderReasonSystem = createSlice({
    name: "other-setting",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setOrderReason: (state, { payload }) => {
            state.listOrderReason = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrderReasonApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrderReasonApi.fulfilled, (state, action) => {
                state.loading = false;
                state.listOrderReason = action.payload;
            })
            .addCase(getOrderReasonApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addOrderReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(addOrderReason.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addOrderReason.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateOrderReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateOrderReason.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateOrderReason.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteOrderReason.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOrderReason.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteOrderReason.rejected, (state, action) => {
                state.loading = false;
            })
    },
});

export const { showLoading, setOrderReason } = OrderReasonSystem.actions;
export default OrderReasonSystem.reducer;