import axiosInstance from "auth/FetchInterceptor";
import {
    GET_ALL_SETTING_HIDE_ORDER, SETTING_HIDE_ORDER_SAVE
} from "constants/ApiConstant";
const SettingHideOrderService = {};

SettingHideOrderService.getAllSettingHideOrder = function (data) {
    return axiosInstance.get(GET_ALL_SETTING_HIDE_ORDER, data);
};
SettingHideOrderService.saveSettingHideOrder = function (data) {
    return axiosInstance.post(SETTING_HIDE_ORDER_SAVE, data);
};


export default SettingHideOrderService;