import axiosInstance from "auth/FetchInterceptor";
import {
    COLLABORATOR_SETTING_API, COLLABORATOR_SETTING_CREATE, COLLABORATOR_SETTING_UPDATE,
    GET_ALL_COLLABORATOR_SETTING, COLLABORATOR_SETTING_SAVE
} from "constants/ApiConstant";
const CollaboratorSettingService = {};

CollaboratorSettingService.getAllCollaboratorSetting = function (data) {
    return axiosInstance.get(GET_ALL_COLLABORATOR_SETTING, data);
};
CollaboratorSettingService.createCollaboratorSetting = function (data) {
    return axiosInstance.post(COLLABORATOR_SETTING_CREATE, data);
};
CollaboratorSettingService.updateCollaboratorSetting = function (data) {
    return axiosInstance.put(COLLABORATOR_SETTING_UPDATE, data);
};
CollaboratorSettingService.saveCollaboratorSetting = function (data) {
    return axiosInstance.post(COLLABORATOR_SETTING_SAVE, data);
};
CollaboratorSettingService.delCollaboratorSetting = function (id) {
    return axiosInstance.delete(`${COLLABORATOR_SETTING_API}/${id}`, null);
};

export default CollaboratorSettingService;