import axiosInstance from "auth/FetchInterceptor";
import {
    COLLABORATOR_API, COLLABORATOR_ALL_BANK, COLLABORATOR_CREATE, COLLABORATOR_UPDATE_PAYMENT, REMOVE_ROLE_COLLABORATOR,
    COLLABORATOR_UPDATE, SEARCH_COLLABORATOR, REPORT_COLLABORATOR, TOP_COLLABORATOR, ORDER_COLLABORATOR, CHECK_COLLABORATOR
} from "constants/ApiConstant";
const CollaboratorService = {};

CollaboratorService.getCollaborator = function (data) {
    return axiosInstance.post(SEARCH_COLLABORATOR, data);
};

CollaboratorService.checkCollaborator = function (data) {
    return axiosInstance.post(CHECK_COLLABORATOR, data);
};

CollaboratorService.getCollaboratorById = function (id) {
    return axiosInstance.get(`${COLLABORATOR_API}/${id}`, null);
};
CollaboratorService.getAllBank = function () {
    return axiosInstance.get(COLLABORATOR_ALL_BANK, null);
};
CollaboratorService.createCollaborator = function (data) {
    return axiosInstance.post(COLLABORATOR_CREATE, data);
};
CollaboratorService.updateCollaborator = function (data) {
    return axiosInstance.put(COLLABORATOR_UPDATE, data);
};
CollaboratorService.updatePaymentCollaborator = function (data) {
    return axiosInstance.put(COLLABORATOR_UPDATE_PAYMENT, data);
};
CollaboratorService.delCollaborator = function (data) {
    return axiosInstance.post(REMOVE_ROLE_COLLABORATOR, data);
};

CollaboratorService.reportOrderCollaborator = function (data) {
    return axiosInstance.post(ORDER_COLLABORATOR, data);
};

CollaboratorService.topCollaborator = function (data) {
    return axiosInstance.post(TOP_COLLABORATOR, data);
};

CollaboratorService.reportCollaborator = function () {
    return axiosInstance.get(REPORT_COLLABORATOR, null);
};
export default CollaboratorService;