import axiosInstance from "auth/FetchInterceptor";
import { PLACE_API, SEARCH_PLACE, PLACE_DETAIL_API, PLACE_CHANGE_STATUS } from "constants/ApiConstant";
const PlaceService = {};


PlaceService.getListPlace = function (data) {
    return axiosInstance.post(SEARCH_PLACE, data);
};
PlaceService.getPlaceById = function (id) {
    return axiosInstance.get(`${PLACE_API}/${id}`, null);
};
PlaceService.createPlace = function (data) {
    return axiosInstance.post(PLACE_API, data);
};
PlaceService.updatePlace = function (data) {
    return axiosInstance.put(PLACE_API, data);
};
PlaceService.delPlace = function (id) {
    return axiosInstance.delete(`${PLACE_API}/${id}`, null);
};
PlaceService.getPlaceDetail = function (id) {
    const url = `${PLACE_DETAIL_API}/${id}`;
    return axiosInstance.get(url, null);
};
PlaceService.changePlaceStatus = function (data) {
    return axiosInstance.put(PLACE_CHANGE_STATUS, data);
};


export default PlaceService;