import axiosInstance from "auth/FetchInterceptor";
import { VOUCHER_API, VOUCHER_SEARCH_GRID, VOUCHER_TICKET_EXPORT_EXEL, VOUCHER_TICKET_SEARCH_GRID, VOUCHER_DETAIL_API, VOUCHER_TICKET_EXPORT_EXEL_HARD_COPY } from "constants/ApiConstant";
const VoucherService = {};

VoucherService.voucherSearchGrid = function (data) {
  return axiosInstance.post(VOUCHER_SEARCH_GRID, data);
};
VoucherService.getDetailVoucher = function (id) {
  const url = `${VOUCHER_DETAIL_API}/${id}`;
  return axiosInstance.get(url, null);
};
VoucherService.getVoucherById = function (id) {
  return axiosInstance.get(`${VOUCHER_API}/${id}`, null);
};
VoucherService.addVoucher = function (data) {
  return axiosInstance.post(VOUCHER_API, data);
};
VoucherService.updateVoucher = function (data) {
  return axiosInstance.put(VOUCHER_API, data);
};
VoucherService.delVoucher = function (id) {
  return axiosInstance.delete(`${VOUCHER_API}/id?id=${id}`, null);
};
VoucherService.getVoucherTicketApi = function (data) {
  return axiosInstance.post(VOUCHER_TICKET_SEARCH_GRID, data);
};
VoucherService.voucherTicketExportExelApi = function (data) {
  return axiosInstance.post(VOUCHER_TICKET_EXPORT_EXEL, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: "blob",
  });
};
VoucherService.voucherTicketExportExelHardCopyApi = function (data) {
  return axiosInstance.post(VOUCHER_TICKET_EXPORT_EXEL_HARD_COPY, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: "blob",
  });
};

export default VoucherService;
