import axiosInstance from "auth/FetchInterceptor";
import { VEHICLE_API, VEHICLE_CREATE, VEHICLE_UPDATE, SEARCH_VEHICLE } from "constants/ApiConstant";
const VehicleService = {};


VehicleService.getListVehicle = function (data) {
    return axiosInstance.post(SEARCH_VEHICLE, data);
};
VehicleService.getVehicleById = function (id) {
    return axiosInstance.get(`${VEHICLE_API}/${id}`, null);
};
VehicleService.createVehicle = function (data) {
    return axiosInstance.post(VEHICLE_CREATE, data);
};
VehicleService.updateVehicle = function (data) {
    return axiosInstance.put(VEHICLE_UPDATE, data);
};
VehicleService.delVehicle = function (id) {
    return axiosInstance.delete(`${VEHICLE_API}/id?id=${id}`, null);
};

export default VehicleService;