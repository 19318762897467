import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Utils from "utils";
import openNotification from "utils/notification";
import cloneDeep from "lodash";
import CollaboratorSettingService from "services/CollaboratorSettingService";

export const getAllCollaboratorSettingApi = createAsyncThunk(
    "collaboratorSetting/getCollaboratorSetting",
    async (data, { rejectWithValue }) => {
        try {
            const response = await CollaboratorSettingService.getAllCollaboratorSetting(data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const createCollaboratorSettingApi = createAsyncThunk(
    "collaboratorSetting/createCollaboratorSetting",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorSettingService.createCollaboratorSetting(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const updateCollaboratorSettingApi = createAsyncThunk(
    "collaboratorSetting/updateCollaboratorSetting",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorSettingService.updateCollaboratorSetting(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const saveCollaboratorSettingApi = createAsyncThunk(
    "collaboratorSetting/saveCollaboratorSetting",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorSettingService.saveCollaboratorSetting(payload);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

export const delCollaboratorSettingApi = createAsyncThunk(
    "collaboratorSetting/delCollaboratorSettingApi",
    async (data, { rejectWithValue }) => {
        try {
            const { onSuccess, id } = data;
            const payload = cloneDeep(data);
            delete payload.onSuccess;
            const response = await CollaboratorSettingService.delCollaboratorSetting(id);
            if (onSuccess) onSuccess(response);
            openNotification(
                "success",
                Utils.setLocale("admin.settings.popup.notification.success"),
                Utils.setLocale("admin.settings.popup.notification.title")
            );
            return response.data;
        } catch (err) {
            return rejectWithValue(err.message || "Error");
        }
    }
);

const initialState = {
    loading: false,
    listBank: [],
    listCollaboratorSetting: [],
    collaboratorSettingDetail: {},
};

export const storeCollaboratorSettingSlice = createSlice({
    name: "collaboratorSetting",
    initialState,
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        setCollaboratorSetting: (state, action) => {
            state.collaboratorSettingList = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCollaboratorSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllCollaboratorSettingApi.fulfilled, (state, action) => {
                state.loading = false;
                state.listCollaboratorSetting = action.payload;
            })
            .addCase(getAllCollaboratorSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(createCollaboratorSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(createCollaboratorSettingApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(createCollaboratorSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCollaboratorSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCollaboratorSettingApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(updateCollaboratorSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(saveCollaboratorSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(saveCollaboratorSettingApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(saveCollaboratorSettingApi.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(delCollaboratorSettingApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(delCollaboratorSettingApi.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(delCollaboratorSettingApi.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { showLoading, setCollaboratorSetting } = storeCollaboratorSettingSlice.actions;

export default storeCollaboratorSettingSlice.reducer;
