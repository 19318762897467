import axiosInstance from "../auth/FetchInterceptor";
import {
    COPY_SHIFT_DAY_API,
    COPY_SHIFT_MONTH_API,
    DRIVER_SHIFT_LIST_SEARCH_GRID,
    LIST_DROPDOWN_DRIVER,
    LIST_SHIFT_MONTHS_YEAR_API,
    LIST_SHIFT_SEARCH_GRID_API,
    UPDATE_LIST_SHIFT_DRIVER
} from "constants/ApiConstant";

const ShiftDriverService = {}

ShiftDriverService.GetListShiftMonthInfo = function (year) {
    return axiosInstance.get(`${LIST_SHIFT_MONTHS_YEAR_API}/${year}`, null);
};
ShiftDriverService.getListShiftSearchGrid = function (data) {
    return axiosInstance.post(LIST_SHIFT_SEARCH_GRID_API, data);
};
ShiftDriverService.getDropdownDriver = function (data) {
    return axiosInstance.post(LIST_DROPDOWN_DRIVER, data);
};
ShiftDriverService.saveUpdateListShiftDriver = function (data) {
    return axiosInstance.post(UPDATE_LIST_SHIFT_DRIVER, data);
};
ShiftDriverService.getDriverShiftListSearchGrid = function (data) {
    return axiosInstance.post(DRIVER_SHIFT_LIST_SEARCH_GRID, data);
};
ShiftDriverService.copyShiftDayApi = function (data) {
    return axiosInstance.post(COPY_SHIFT_DAY_API, data);
};
ShiftDriverService.copyShiftMonthApi = function (data) {
    return axiosInstance.post(COPY_SHIFT_MONTH_API, data);
};


export default ShiftDriverService;